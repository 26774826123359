import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { resetStores } from '@datorama/akita';
import { UserQuery } from 'src/app/store/user/user.query';
import { User } from 'src/app/services/dto/user-model';
import { Platform } from '@ionic/angular';
import { ProfileMenuPopoverComponent } from './profile-menu-popover/profile-menu-popover.component';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
})
export class ProfileButtonComponent implements OnInit {
  constructor(
    private helperService: HelperService,
    private router: Router,
    private userQuery: UserQuery,
    private platform: Platform
  ) {
    this.userQuery.userInfo$.subscribe((data) => {
      this.user = data;
    });
  }

  user: User;

  ngOnInit() { }

  openProfile(e) {
    let android = this.platform.is('android');
    let ios = this.platform.is('ios');
    if (android || ios) {
      this.openActionSheet();
    } else {
      this.openPopOver(e);
    }
  }

  openPopOver(e) {
    this.helperService.openPopOver(e, ProfileMenuPopoverComponent);
  }


  openActionSheet() {
    this.helperService.openActionSheet({
      header: this.user?.name,
      cssClass: 'custom-profile',
      buttons: [
        {
          text: 'Profile Git',
          icon: 'person-circle-outline',
          handler: () => {
            this.router.navigate(['main/profile']);
          },
        },
        {
          text: 'Banka Hareketleri',
          icon: 'wallet-outline',
          handler: () => {
            this.router.navigate(['main/account-transactions']);
          },
        },
        {
          text: 'Çıkış Yap',
          icon: 'log-out-outline',
          handler: () => {
            localStorage.clear();
            resetStores();
            this.router.navigate(['login']);
          },
        },
        {
          text: 'İptal',
          icon: 'close',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
  }
}
