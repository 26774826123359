import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helpers/helper.service';

@Component({
  selector: 'app-attribute-list-modal',
  templateUrl: './attribute-list-modal.component.html',
  styleUrls: ['./attribute-list-modal.component.scss'],
})
export class AttributeListModalComponent implements OnInit {
  constructor(public h: HelperService) {}
  data = [];
  attributes = [];
  ngOnInit() {
    this.attributes = this.attributes.map((elm) => {
      let found = this.data.find((dt) => dt.id === elm.id);

      if (found) {
        elm['checked'] = true;
      }

      return elm;
    });
  }

  changeCheckbox(e, item) {
    let checked = e.detail?.checked;
    let foundIndex = this.data.findIndex((elm) => elm.id === item.id);
    if (foundIndex >= 0 && !checked) {
      this.data.splice(foundIndex, 1);
    } else {
      if (checked) {
        this.data.push(item);
      }
    }
  }

  close() {
    this.h.closeModal(this.data,'attr');
  }
}
