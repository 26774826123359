import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { OrwiStoreStore, OrwiStoreState } from './orwi-store.store';



@Injectable({ providedIn: 'root' })
export class OrwiStoreQuery extends Query<OrwiStoreState> {
    activeStore$ = this.select('activeStore')
    ownedStore$ = this.select('ownedStore')
    staffs$ = this.select('staffs')
    categories$ = this.select('categories')
    bannerImages$ = this.select('bannerImages')
    popupImages$ = this.select('popupImages')
    constructor(protected store: OrwiStoreStore) {
        super(store);
    }

    
}