import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { LoadingOptions } from '@ionic/core';
import { Workbook } from 'exceljs';
import { Product } from './dto/menu-model';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  maxDate = moment().add(10, 'year').toISOString();
  async closeAllLoading() {
    await this.loadingCtrl.getTop().then(async (loadings) => {
      await loadings?.dismiss();
      if (loadings !== undefined) {
        //        console.log('LOADINGS: closeAllLoading ', loadings);
        document.querySelector('ion-loading')?.remove();
        await loadings?.dismiss();
        await this.closeAllLoading();
      }
    });
  }
  async hasLoading() {
    return (await this.loadingCtrl.getTop()) ?? false;
  }
  networkConnected = true;
  dbName: string = '';
  ipAddress: string = '';
  oLoading: HTMLIonLoadingElement;
  listItemSlidingHelper = false;
  socketId = '';
  constructor(
    private translocoService: TranslocoService,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private plt: Platform,
    private alertCtrl: AlertController
  ) {}

  async toast(
    header,
    message,
    position: 'top' | 'middle' | 'bottom',
    color: 'danger' | 'warning' | 'success',
    duration: number = 2000,
    button: boolean = false
  ) {
    let config = {
      header: this.translocoService.translate(header),
      message: this.translocoService.translate(message),
      position: position,
      color: color,
      animated: true,
      cssClass: 'toast',
    };

    if (button) {
      config['buttons'] = [
        {
          text: this.translocoService.translate('Yes'),
          role: 'cancel',
          handler: () => {
            this.consolelog('Cancel clicked');
          },
        },
        {
          text: this.translocoService.translate('No'),
          role: 'cancel',
          handler: () => {
            this.consolelog('Cancel clicked');
          },
        },
      ];
    } else {
      config['duration'] = duration;
    }

    const toast = await this.toastCtrl.create(config);
    toast.present();
  }

  consolelog(
    log1,
    log2 = undefined,
    log3 = undefined,
    type: 'public' | 'private' = 'private',
    logType: 'log' | 'error' | 'warn' = 'log'
  ) {
    // if (type == "private" && document.URL.indexOf("localhost") == -1) {
    if (document.URL.indexOf('localhost') !== -1) {
      //return
    }
    if (logType == 'log') {
      console.log(log1, log2, log3);
    } else if (logType == 'error') {
      console.error(log1, log2, log3);
    } else {
      console.warn(log1, log2, log3);
    }
  }

  LANGUAGES = [
    {
      id: 'tr',
      name: 'Türkçe',
    },
    {
      id: 'en',
      name: 'English',
    },
    {
      id: 'de',
      name: 'Deutsch',
    },
    {
      id: 'ar',
      name: 'عربى',
    },
    {
      id: 'ru',
      name: 'Русский',
    },
    {
      id: 'ge',
      name: 'ქართული',
    },
    {
      id: 'nl',
      name: 'Nederlands',
    },
  ];

  async showLoading(m: string = '', extraOptions?: LoadingOptions) {
    let options: LoadingOptions = {
      message:
        '<ion-img src="/assets/icon/orwi-jump.png" alt="loading..."></ion-img>',
      cssClass: 'my-loading-class',
      translucent: false,
      showBackdrop: false,
      spinner: null,
      keyboardClose: true,
      duration: 3000,
      ...extraOptions,
    };
    console.log('LOADING', await this.hasLoading())
    if (await this.hasLoading()) return;
    this.oLoading = await this.loadingCtrl.create(options);
    await this.oLoading.present();
    return this.oLoading;
  }

  closeLoading(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.oLoading) {
        this.oLoading.dismiss();
        this.oLoading = undefined;
      }
      setTimeout(() => {
        if (this.oLoading) {
          this.oLoading.dismiss();
          this.oLoading = undefined;
        }
        resolve(true);
      }, 500);
    });
  }

  async jsonToExcelExporter(data, fileName, dataM: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Folio List');
    let columns = [];
    let headRow = {};
    let itemRow = {};
    let dataModel = new dataM();
    Object.keys(dataModel).map((objectKey) => {
      let calculatedWidth;
      objectKey.split('').length > 14
        ? (calculatedWidth = 16)
        : (calculatedWidth = 12);
      let column = {
        key: objectKey,
        width: calculatedWidth,
        font: { bold: true },
      };
      columns = [...columns, column];
      headRow[objectKey] = objectKey;
    });
    worksheet.columns = [...columns];

    worksheet.insertRow(0, {});
    worksheet.addRow(headRow, 'i');

    await data.forEach((dataItem) => {
      itemRow = {};
      Object.keys(dataModel).forEach((objectKey) => {
        itemRow[objectKey] = dataItem[objectKey];
      });
      worksheet.addRow(itemRow, 'i');
    });

    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        fileName + '.xlsx'
      );
    });
  }

  hapticsImpact(style = ImpactStyle.Heavy) {
    if (this.plt.is('capacitor')) {
      Haptics.impact({
        style: style,
      });
    }
  }

  qrReaded() {
    this.hapticsImpact(ImpactStyle.Light);
    setTimeout(() => {
      this.hapticsImpact(ImpactStyle.Heavy);
      setTimeout(() => {
        this.hapticsImpact(ImpactStyle.Light);
        setTimeout(() => {
          this.hapticsImpact(ImpactStyle.Medium);
        }, 100);
      }, 100);
    }, 100);
  }

  async orwiAlert(header, subtitle, message, buttons) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: header,
      subHeader: subtitle,
      message: message,
      buttons: buttons,
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        let x = 0;
        let y = 0;

        if (width > maxWidth || height > maxHeight) {
          const ratio = Math.min(maxWidth / width, maxHeight / height);
          width *= ratio;
          height *= ratio;
        } else if (width < maxWidth && height < maxHeight) {
          const ratio = Math.max(maxWidth / width, maxHeight / height);
          width *= ratio;
          height *= ratio;
        }

        const canvas = document.createElement('canvas');
        x = (maxWidth - width) / 2;
        y = (maxHeight - height) / 2;
        canvas.width = maxWidth;
        canvas.height = maxHeight;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#F7F7F7';
        ctx.fillRect(0, 0, maxWidth, maxHeight);
        ctx.drawImage(img, 0, 0, img.width, img.height, x, y, width, height);
        console.log(canvas.toDataURL());
        canvas.toBlob(
          (blob) => {
            const fileName = file.name;
            const fileType = file.type;
            const lastModified = file.lastModified;
            const resizedFile = new File([blob], fileName, {
              type: fileType,
              lastModified: lastModified,
            });
            console.log('Image Resized', fileName, width, height);
            resolve(resizedFile);
          },
          'image/jpeg',
          1
        );
      };
    });
  }

  async presentAlertConfirm(alertOptions?: any) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm!',
      message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Okay',
          role: 'confirm',
        },
      ],
      ...alertOptions,
    });

    await alert.present();

    return alert;
  }
}
