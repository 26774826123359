import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CdnService {
  constructor(private http: HttpClient) {}

  uploadImage(formData: FormData, id?:string) {
    return this.http.post(
      environment.cdnService + '/api/file/uploadFile',
      formData,
      {
        headers: new HttpHeaders({
            'external-id': "orwi-qr-" + id
        })
      }
    );
  }

  getImage(id: string) {
    let url = environment.cdnService + '/api/file/' + id
    console.log(url)
    return this.http.get(environment.cdnService + '/api/file/' + id);
  }
}
