import { Component, OnInit } from '@angular/core';
import { KerzzButtonComponent } from '../button.base';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent extends KerzzButtonComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
