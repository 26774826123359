import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LegacyNotesComponent } from './pages/legacy-notes/legacy-notes.component';
import AuthGuard from './services/auth-guard';
import OffLineGuard from './services/offline-guard';

const routes: Routes = [
  {
    path: 'legal-notes/view/:type/:lang',
    component: LegacyNotesComponent
  },
  {
    path: 'legal-notes/view/:type',
    component: LegacyNotesComponent
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [OffLineGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
    canActivate: [OffLineGuard],
  },

  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  }

 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
