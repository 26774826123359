import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Country } from 'src/app/services/dto/general-model';
import { GeneralService } from 'src/app/services/general.service';
import {
  PhoneNumberUtil,
  PhoneNumber,
  PhoneNumberFormat,
} from 'google-libphonenumber';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helpers/helper.service';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit {
  constructor(
    private generalService: GeneralService,
    private helper: HelperService
  ) {}
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  countries: Country[] = [];
  activeCode: string = 'TR';
  activeCountry: Country = undefined;

  @Output()
  onEntered = new EventEmitter();
  
  firstInit = true;
  ngOnInit() {
    this.generalService.Countries.then(
      (res) => {
        this.countries = res;
        this.changeActiveCountry('TR');
      },
      (err) => {
        this.changeActiveCountry('TR');
      }
    );
    // this.control.valueChanges.subscribe(data => {
    //   console.log((this.control.value as string).replace('(','').replace(')','').split(' ').join(''));
    //   try {
    //     const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(
    //       (this.control.value as string).replace('(','').replace(')','').split(' ').join(''), this.activeCode
    //     );
    //     console.log(this.phoneNumberUtil.isValidNumber(phoneNumber))
    //   } catch (error) {

    //   }
    // })
  }

  async openModal() {
    let countryModal = await this.helper.openModal(
      PhoneCountryModal,
      {
        selectedCountry: this.activeCountry,
      },
      'full-screen',
      true
    );
    countryModal.onDidDismiss().then((res) => {
      if (res.data?.selectedCountry) {
        this.changeActiveCountry(res.data?.selectedCountry.code);
      }
    });
  }

  resolvePlaceHolder() {
    return this.phoneNumberUtil.format(
      this.phoneNumberUtil.getExampleNumber(this.activeCode),
      PhoneNumberFormat.NATIONAL
    ).replace("0", "");
  }

  get mask() {
    return this.resolvePlaceHolder()
      .split('')
      .map((elm) =>
        Number.isNaN(+elm) || elm.trim().length == 0 || elm === '0' ? elm : 9
      )
      .join('')
      .replace("0", "");;
  }

  @Input()
  control: any;

  getError(errorType = 'required') {
    return this.control.hasError(errorType) && this.control.touched;
  }

  changeActiveCountry(code) {
    this.activeCode = code;
    this.activeCountry = this.countries.find((el) => el.code == code);
    localStorage.setItem('activeCountryCode', code || 'TR');
    localStorage.setItem(
      'activeDialCode',
      this.activeCountry?.dialCode || '+90'
    );
    !this.firstInit && this.control.reset();
    this.firstInit = false;
  }
}

@Component({
  selector: 'app-phone-country-modal',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="helper.closeModal()">
            <ion-icon icon="arrow-back-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-searchbar
          (ionChange)="searchCountry(searchBar.value)"
          #searchBar
          [placeholder]="'search' | transloco"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item
          *ngFor="let country of countries"
          (click)="selectCountry(country)"
        >
          <span style="margin-right: 10px;">{{ country.flagEmoji }}</span>
          ({{ country.dialCode }})
          <ion-text slot="end">
            {{ country.name }}
          </ion-text>
        </ion-item>
        <ion-item *ngIf="countries.length == 0 && !loading" lines="none">
          {{ 'not found' | transloco }}
        </ion-item>
        <ion-item *ngIf="countries.length == 0 && loading" lines="none">
          {{ 'loading' | transloco }}
        </ion-item>
      </ion-list>
    </ion-content>
  `,
})
export class PhoneCountryModal implements OnInit {
  constructor(
    private generalService: GeneralService,
    public helper: HelperService
  ) {}

  countries: Country[] = [];
  allCountries: Country[] = [];

  loading = true;
  ngOnInit(): void {
    this.generalService.Countries.then(
      (res) => {
        this.countries = this.allCountries = res.filter(elm => elm.dialCode);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  searchCountry(searchTerm: string) {
    this.countries = this.allCountries.filter((elm) =>
      elm.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
  }

  selectCountry(country) {
    this.helper.closeModal({
      selectedCountry: country,
    });
  }
}
