import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrwiTables } from 'src/app/services/dto/orwi-tables-model';
import { GlobalService } from 'src/app/services/global.service';
import { OrwiTableService } from 'src/app/store/orwi-tables/orwitable.service';
import { TABLE_ACTION_TYPES } from '../orwi-tables-main/orwi-tables-main.page';

@Component({
  selector: 'app-orwi-table-action-change',
  templateUrl: './orwi-table-action-change.component.html',
  styleUrls: ['./orwi-table-action-change.component.scss'],
})
export class OrwiTableActionChangeComponent implements OnInit {
  orwiSelectedTable : OrwiTables[] = [];
  selectedAction : string = ""
  constructor(private orwiTableStoreService : OrwiTableService, private modalController: ModalController,private glb : GlobalService) { }

  ngOnInit() {}

  actions = TABLE_ACTION_TYPES;
  // [
  //   {
  //     id: 'take-away',
  //     name: 'Take Away'
  //   },
  //   {
  //     id: 'in-store',
  //     name: 'Table Service'
  //   },
  //   {
  //     id: 'only-menu',
  //     name: 'Only Menu'
  //   }, {
  //     id: 'loyalty',
  //     name: 'Loyalty'
  //   }
  //   , {
  //     id: 'payment',
  //     name: 'Payment'
  //   }    
  // ]

  saveTables()
  {  
    debugger
    if (this.selectedAction == "")
    {
      this.glb.toast('Table Action','Please Make a Selection',"bottom","warning")
      return
    }
    this.glb.showLoading()
   this.orwiSelectedTable.forEach(element => {
     element.startAction = this.selectedAction
     this.orwiTableStoreService.saveStoreTable(element)
   });
   setTimeout(() => {
     this.glb.closeLoading()
     this.glb.toast('Table Action','Table Types Updated.','bottom','success')
   }, 2000);
  }

  close()
  {
    this.modalController.dismiss()
  }


  onSelectedChanged(e)
  {    
    this.selectedAction = e.selectedItem.id
  }

}
