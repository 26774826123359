import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { OrwiStore } from 'src/app/services/dto/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-week-hours',
  templateUrl: './week-hours.component.html',
  styleUrls: ['./week-hours.component.scss'],
})
export class WeekHoursComponent implements OnInit {
  workDays = [];

  orwiStore: OrwiStore;

  constructor(
    public h: HelperService,
    private fb: FormBuilder,
    private orwiStoreService: OrwiStoreService,
    private glb: GlobalService,
    private transloco: TranslocoService
  ) {}

  form = this.fb.group({
    workingHours: this.fb.array([]),
  });

  getDayName(day: AbstractControl) {
    return this.h.days[day.get('day').value];
  }

  get workingHours() {
    return this.form.get('workingHours') as FormArray;
  }

  emptyDays = [
    {
      day: 0,
      open: '09:00',
      close: '23:00',
      closed: false,
    },
    {
      day: 1,
      open: '09:00',
      close: '23:00',
      closed: false,
    },
    {
      day: 2,
      open: '09:00',
      close: '23:00',
      closed: false,
    },
    {
      day: 3,
      open: '09:00',
      close: '23:00',
      closed: false,
    },
    {
      day: 4,
      open: '09:00',
      close: '23:00',
      closed: false,
    },
    {
      day: 5,
      open: '09:00',
      close: '23:00',
      closed: false,
    },
    {
      day: 6,
      open: '09:00',
      close: '23:00',
      closed: false,
    },
  ];

  ngOnInit() {
    this.workDays = this.orwiStore.workingHours;
    if (this.orwiStore.workingHours.length != 0) {
      this.orwiStore.workingHours.forEach((elm) => {
        this.addGroup(elm);
      });
    } else {
      this.emptyDays.forEach((elm) => this.addGroup(elm));
    }
  }

  addGroup(data?: any) {
    let arr = this.workingHours;
    arr.push(
      this.fb.group({
        day: [data?.day, Validators.required],
        open: [data?.open, Validators.required],
        close: [data?.close, Validators.required],
        closed: [data?.closed],
      })
    );
  }

  send() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.glb.showLoading();
      this.orwiStoreService
        .updateStore({
          ...this.orwiStore,
          workingHours: this.form.value.workingHours,
        })
        .then(
          (response: any) => {
            this.glb.toast('', 'Updated Successfully!', 'bottom', 'success');
            this.glb.closeLoading();
            this.h.closeModal({
              updateStore: response,
            });
          },
          (err) => {
            this.glb.closeLoading();
            this.glb.toast('', 'There was an error!', 'bottom', 'danger');
          }
        );
    }
  }

  close() {
    if (this.form.dirty) {
      this.showAlert(() => {
        this.h.closeModal();
      });
    } else {
      this.h.closeModal();
    }
  }

  showAlert(cb: Function) {
    this.h.openAlert({
      header: this.transloco.translate('Notification'),
      subHeader: this.transloco.translate('You have unsaved changes. Do you want to leave?'),
      buttons: [
        {
          text: this.transloco.translate('No'),
          handler: () => {},
        },
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            cb();
          },
        },
      ],
    });
  }

  getMin(i: number) {
    return this.workingHours.at(i).get('open').value;
  }

  getMax(i: number) {
    return this.workingHours.at(i).get('close').value;
  }
}
