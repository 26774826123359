import { NgModule } from '@angular/core';

import { LocalizedDatePipe } from './orwi-date.pipe';
import { ArrayFilterPipe } from './array-filter.pipe';
import { ArraySorterPipe } from './array-sorter.pipe';
import { MaxDatePipe } from './max-date.pipe';

const PIPES = [
    LocalizedDatePipe,
    ArrayFilterPipe,
    ArraySorterPipe,
    MaxDatePipe
];

@NgModule({
    imports: [],
    declarations: [...PIPES],
    providers: [],
    exports: [...PIPES]
})
export class PipesModule {
}