import { Component, Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrwiStoreQuery } from 'src/app/store/orwi/orwi-store.query';

/**
 * Sayfaya girişlerde ve çıkışlarda subscription/unsubscription yönetimi yapar.
 * @param SMConfig cinsinden array alır.
 * @param >query
 * @param >service
 * @param >store
 * @param >storeKey
 * @returns dataChange(...data[]) Gelen array deki sıraya göre veriler döner.
 */
export abstract class SubscribeManager {
  constructor(public config: SMConfig[]) {}

  subs: Subscription;
  isFetchings = [];
  ionViewDidEnter() {
    console.log('ionViewDidEnter');
    // this.isFetchings = [
    //   ...this.config.map((e, i) => ({ status: false, data: null })),
    // ];
    this.storeQuery$.subscribe(store => {
      this.isFetchings = [
        ...this.config.map((e, i) => ({ status: false, data: null })),
      ];
    })
    let arr = [...this.config.map((t) => t.query), this.storeQuery$];
    this.subs = combineLatest(arr).subscribe((data) => {
      this.config.forEach(({ service, store, storeKey }, index) => {
        (data as any[]).forEach(async (element) => {
          if (
            ((element instanceof Array && element.length == 0) ||
              (element instanceof Object &&
                Object.keys(element).length == 0)) &&
            !this.isFetchings[index].status &&
            this.isFetchings[index].data == null
          ) {
            await this.getServiceData(service, store, storeKey, index);
          }
        });
      });

      this.dataChange(...data);
    });

    
  }

  ionViewWillLeave() {
    this.subs.unsubscribe();
    this.isFetchings = [];
  }
  /**
   *
   * @param data[] Super de verilen sıraya göre veriler döner.
   */
  abstract dataChange(...data: any[]): void;
  abstract myInjector(): Injector;

  async getServiceData(service, store, storeKey, index) {
    this.isFetchings[index].status = true;
    await service().then(
      (res) => {
        if (
          (res instanceof Array && res.length > 0) ||
          (res instanceof Object && Object.keys(res).length > 0)
        ) {
          if (storeKey instanceof Array) {
            let stores = {};
            storeKey.forEach((k: StoreKey) => {
              stores[k.key] = res[k.serviceKey];
            });
            store.update(stores);
          } else {
            store.update({
              [storeKey]: res,
            });
          }
        }
        this.isFetchings[index] = { status: false, data: res };
      },
      (err) => {
        this.isFetchings[index] = { status: false, data: err };
      }
    );
  }

  private get storeQuery$() {
    return this.myInjector().get(OrwiStoreQuery).activeStore$;
  }
}

export interface SMConfig {
  query: Observable<any>;
  service: () => void;
  store: any;
  storeKey: string | StoreKey[];
}

interface StoreKey {
  key: string;
  serviceKey: string;
}
