import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import {
  DxAccordionModule,
  DxButtonModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxTileViewModule,
} from 'devextreme-angular';
import { OtpFormComponent } from './components/otp-form/otp-form.component';
import { SelectLangComponent } from './components/select-lang/select-lang.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfileButtonComponent } from './components/profile-button/profile-button.component';
import { ProfileMenuPopoverComponent } from './components/profile-button/profile-menu-popover/profile-menu-popover.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { LiveSupportButtonComponent } from './components/live-support-button/live-support-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneCountryModal, PhoneInputComponent } from './components/phone-input/phone-input.component';
import { BrMaskerModule } from 'br-mask';
import { StoreListModalComponent } from './components/store-list-modal/store-list-modal.component';
import { StoreListPage } from '../pages/main/store-list/store-list.page';
import { PipesModule } from './pipes/pipes.module';
import { ImageShowerComponent } from './components/image-shower/image-shower.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { DeliveryHoursComponent } from './components/delivery-hours/delivery-hours.component';
import { WeekHoursComponent } from './components/week-hours/week-hours.component';
import { CountrySelectboxComponent } from './components/country-selectbox/country-selectbox.component';
import { CitySelectboxComponent } from './components/city-selectbox/city-selectbox.component';
import { TownSelectboxComponent } from './components/town-selectbox/town-selectbox.component';
import { DefinitionsTableComponent } from './components/definitions-table/definitions-table.component';
import { EditButtonComponent } from './components/buttons/edit-button/edit-button.component';
import { CreateButtonComponent } from './components/buttons/create-button/create-button.component';
import { DeleteButtonComponent } from './components/buttons/delete-button/delete-button.component';
import { KerzzButtonComponent } from './components/buttons/button.base';
import { AttributeListModalComponent } from './components/attribute-list-modal/attribute-list-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SyncButtonComponent } from './components/sync-button/sync-button.component';
import { OrwiTableActionChangeComponent } from '../pages/main/orwi-tables/orwi-table-action-change/orwi-table-action-change.component';
import { StaffPermissionModalComponent } from './components/staff-permission-modal/staff-permission-modal.component';
import { HomeButtonComponent } from './components/home-button/home-button.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { OrwiSelectComponent } from './components/orwi-select/orwi-select.component';

const DEV_EXTREME_COMPONENTS = [
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  TranslocoModule,
  FormsModule,
  BrMaskerModule,
  DxAccordionModule,
  DxSpeedDialActionModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxSelectBoxModule,
  DxTileViewModule,
  DxPieChartModule,
  DxHtmlEditorModule,
  DxTagBoxModule,
  NgOtpInputModule,
  DxNumberBoxModule,
];

const COMPONENTS = [
  HomeButtonComponent,
  OtpFormComponent,
  SidebarComponent,
  SelectLangComponent,
  ProfileButtonComponent,
  SyncButtonComponent,
  SearchBarComponent,
  LiveSupportButtonComponent,
  PhoneInputComponent,
  StoreListModalComponent,
  StoreListPage,
  ProfileMenuPopoverComponent,
  ImageShowerComponent,
  ImageUploaderComponent,
  WeekHoursComponent,
  DeliveryHoursComponent,
  CountrySelectboxComponent,
  CitySelectboxComponent,
  TownSelectboxComponent,
  DefinitionsTableComponent,
  EditButtonComponent,
  CreateButtonComponent,
  DeleteButtonComponent,
  KerzzButtonComponent,
  AttributeListModalComponent,
  PhoneCountryModal,
  OrwiTableActionChangeComponent,
  StaffPermissionModalComponent,
  StarRatingComponent,
  OrwiSelectComponent
];

const MODULES = [PipesModule, ReactiveFormsModule, FormsModule];

@NgModule({
  declarations: [...COMPONENTS],
  entryComponents: [],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    IonicModule.forRoot(),
    ...DEV_EXTREME_COMPONENTS,
    ...MODULES,
  ],
  exports: [
    ...DEV_EXTREME_COMPONENTS,
    NgSelectModule,
    ...COMPONENTS,
    ...MODULES,
    IonicModule,
  ],

  providers: [],
  bootstrap: [],
})
export class SharedModule {}
