import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonSearchbar } from '@ionic/angular';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  @Input()
  icon = 'search-outline';

  @Input()
  fullWidth: boolean = false;

  @Output()
  onChangeInput = new EventEmitter();

  searchInput = new FormControl();

  @ViewChild('search', {static: false}) search: IonSearchbar;

  setFocus() {
    this.search.setFocus();
  }

  constructor() { }

  ngOnInit() {
    this.searchInput.valueChanges.subscribe(data => {
      this.onChange(data);
    })
  }


  onChange(e) {
    this.onChangeInput.emit(e);
  }
}
