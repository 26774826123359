import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LegalNote,
  LegalNotesTypes,
} from 'src/app/services/dto/legal-notes-model';
import { GlobalService } from 'src/app/services/global.service';
import { LegalNoteService } from 'src/app/store/legal-notes/legal-note.service';

@Component({
  selector: 'app-legacy-notes',
  templateUrl: './legacy-notes.component.html',
  styleUrls: ['./legacy-notes.component.scss'],
})
export class LegacyNotesComponent implements OnInit {
  constructor(
    private legacyNoteService: LegalNoteService,
    private titleService: Title,
    private router: Router,
    private acRoute: ActivatedRoute,
    private domSanit: DomSanitizer,
    private glb: GlobalService
  ) {
    this.acRoute.params.subscribe((data) => {
      let lang = data.lang;
      if (!data.lang || !glb.LANGUAGES.some((lng) => lng.id == data.lang)) {
        lang = 'tr';
      }
      
      if (data.type && LegalNotesTypes.some((lg) => lg == data.type)) {
        this.legacyNoteService.getAll().then(
          (response) => {
            this.selectedNote = response.find((elm) => elm.type == data.type && elm.lang == lang);
            if(this.selectedNote) {
              this.titleService.setTitle(
                `${this.titleService.getTitle()} | ${this.selectedNote.caption}`
              );
              return;
            }

            this.router.navigate(['/']);
          },
          (err) => {
            this.router.navigate(['/']);
          }
        );
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  selectedNote: LegalNote;

  ngOnInit() {}

  getSanit() {
    return this.domSanit.bypassSecurityTrustHtml(this.selectedNote?.detail);
  }

  getApiKeyForDomain(): string | undefined {
    let subDomain = window.location.hostname.split('.')[0];
    let apiKey = this.domains[subDomain];
    return apiKey;
  }

  domains = {
    numnum:
      'TldZeE5UbGpaVFZrWjIzZmYzMmYyM2YycTNmbVZpWmpFd09UVmlOekJsWmpBMHNkZjMycmYyZjNmMmYyM2Y',
    beltur:
      'V1cxV2MweFlVakZqYlhld3dlcndlZmhyV20xMGMyRXpUbWhhUnpWdFlXNWtiVnBZWkd4a2JWWnR3cQ',
    bodrum: 'Ym9kdW0tYmVsLTMyNDgyxLFmajMyNDk4dDNnNGg5xLE4NDNoZ3bEsXV1OiE',
    mackbear: 'bWFjLWJlYXItNDE1MjNmeGQyM2VyMmZlYzIg',
    main: 'YWxpLTEyMzQ1MTEyNDU2NTQzMg',
    localhost: 'YWxpLTEyMzQ1MTEyNDU2NTQzMg',
  };
}
