import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Table, TableGroup } from 'src/app/services/dto/table-model';
import { OrwiTableState, OrwiTableStore } from './orwi-table-store';

@Injectable({ providedIn: 'root' })
export class OrwiTableQuery extends Query<OrwiTableState> {
  tables$ = this.select('orwiTables');
  tableGroups$ = combineLatest([this.select('orwiTableGroups'), this.tables$]).pipe(
    map(this.joinItems)
  );

  tableGroupsOnly$ = this.select("orwiTableGroups");
  constructor(protected store: OrwiTableStore) {
    super(store);
  }

  joinItems([tableGroups, tables]) {
      return tableGroups.map((tbgrps) => {
          let tbles = tables.filter((tb) => tb.name == tbgrps);
            // console.log({
            //     ...tbgrps,
            //     tables: tbles
            // })
          return {
              ...tbgrps,
              tables: tbles
          }
      })
  }
}
