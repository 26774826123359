import { Component, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, FormArray } from '@angular/forms';
import { OrwiStore } from 'src/app/services/dto/store-model';
import { HelperService } from 'src/app/services/helpers/helper.service';

@Component({
  selector: 'app-delivery-hours',
  templateUrl: './delivery-hours.component.html',
  styleUrls: ['./delivery-hours.component.scss'],
})
export class DeliveryHoursComponent implements OnInit {

  workDays = [];

  orwiStore: OrwiStore;

  constructor(public h: HelperService,private fb: FormBuilder) { }


  form = this.fb.group({
    deliveryHours: this.fb.array([])
  });

  getDayName(day: AbstractControl) {
     
    return this.h.days[day.get('day').value];
  }

  get deliveryHours(): FormArray {
    return this.form.get('deliveryHours') as FormArray;
  }

  ngOnInit() {
    this.workDays = new Array(7);
    new Array(7).forEach(elm => {this.addGroup(elm)});

     
  }

  addGroup(data: any) {
    let arr = this.deliveryHours.controls;
    arr.push(this.fb.group({
      day:[data?.day],
        open: [data?.open],
        close: [data?.close],
        closed: [data?.closed],
    }))
  }


  send() {
    console.log(this.form.get('deliveryHours').value);
  }



}
