import { EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Component } from '@angular/core';

@Component({
  selector: 'kerzz-button',
  template: `
    <ion-button
      [fill]="fill"
      [color]="color"
      [expand]="expand"
      [disabled]="disabled"
      [ngClass]="{
        'ion-no-padding': !showTitle || noPadding,
        'ion-no-margin': !showTitle || noMargin
      }"
      (click)="click($event)"
    >
      <i class="material-icons-outlined" *ngIf="icon"> {{ icon }} </i>
      <span style="text-transform: capitalize;"
            [ngClass]="{'btn-desc': !showTitle,'d-none': !title}" *transloco="let t">
        {{ t(title) }} </span>
    </ion-button>
  `,
})
export class KerzzButtonComponent implements OnInit {
  ngOnInit(): void {}

  @Input()
  icon: string = undefined;

  @Input()
  color: string = 'primary';

  @Input()
  fill: string = 'clear';

  @Input()
  expand: string = 'block';

  @Input()
  title: string;

  @Input()
  showTitle: boolean = true;

  @Input()
  noPadding: boolean = false;

  @Input()
  noMargin: boolean = false;

  @Output()
  onClick = new EventEmitter();

  disabled = false;

  @Input()
  set isDisable(e) {
    this.disabled = e;
  }

  click(e) {
    e.preventDefault();
    e.stopPropagation();
    this.onClick.emit();
  }
}
