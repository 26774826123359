
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { OrwiStore } from 'src/app/services/dto/store-model';
import { User, UserOwnedStore } from 'src/app/services/dto/user-model';


export interface UserState {
  userInfo: User,
  token: string,
  ownedStores: UserOwnedStore[],
}

export function createInitialState(): UserState {
  return {
    token: '',
    userInfo: new User(),
    ownedStores: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user'})
export class UserStore extends Store<UserState> {
    constructor() {
        super(createInitialState());    
    }

}