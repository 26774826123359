import { Component, OnInit, Output,EventEmitter, Input} from '@angular/core';
import { Country } from 'src/app/services/dto/general-model';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-country-selectbox',
  templateUrl: './country-selectbox.component.html',
  styleUrls: ['./country-selectbox.component.scss'],
})
export class CountrySelectboxComponent implements OnInit {


  @Output()
  onSelectCountry = new EventEmitter();

  @Input()
  selectedCountry: string;

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
    this.generalService.Countries.then(o => {
      this.countries = o;
    })
  }


  countries: Country[] = [];


  selectCountry(e) {
    let value = e;
    let selectedCountry = this.countries.find(ctr => ctr.name == value);
    this.onSelectCountry.emit(selectedCountry);
  }

}
