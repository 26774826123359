import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-image-shower',
  templateUrl: './image-shower.component.html',
  styleUrls: ['./image-shower.component.scss'],
})
export class ImageShowerComponent implements OnInit {

  @Input()
  img: string;

  @Input()
  link: string;

  @Input()
  width: number = 250;

  @Input()
  height: number | string = this.width;


  @Input()
  title:string;

  constructor() { }

  ngOnInit() { }



}
