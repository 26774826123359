import { Component, OnInit } from '@angular/core';
import { SupportModalComponent } from 'src/app/pages/main/support-modal/support-modal.component';
import { HelperService } from 'src/app/services/helpers/helper.service';

@Component({
  selector: 'app-live-support-button',
  templateUrl: './live-support-button.component.html',
  styleUrls: ['./live-support-button.component.scss'],
})
export class LiveSupportButtonComponent implements OnInit {

  constructor(private helper: HelperService) { }

  ngOnInit() {}

  openModal() {
    this.helper.openModal(SupportModalComponent, {}, 'kerzz-modal');
  }

}
