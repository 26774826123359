import { Component, OnInit } from '@angular/core';
import { KerzzButtonComponent } from '../button.base';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent extends KerzzButtonComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}


}
