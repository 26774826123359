import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { City } from 'src/app/services/dto/general-model';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-city-selectbox',
  templateUrl: './city-selectbox.component.html',
  styleUrls: ['./city-selectbox.component.scss'],
})
export class CitySelectboxComponent implements OnInit {
  @Input()
  set countryCode(e) {
    setTimeout(() => {
      this.getCities(e);
    }, 3000);
  }

  @Input()
  set selectedCity(e) {
    this._selectedCity = e;
  }

  _selectedCity;

  @Output()
  onSelectCity = new EventEmitter();

  cities: City[] = [];

  @Output()
  loaded = new EventEmitter();

  constructor(private generalService: GeneralService) {}

  ngOnInit() {}

  getCities(e) {
    if (e) {
      this.generalService.getCities(e).then((o) => {
        this.cities = o;
        if (this._selectedCity) {
          this.selectCity(this._selectedCity);
        }
        this.loaded.emit(true);
      });
    }
  }

  selectCity(e: any) {
    // console.log(e);

    let cityName: string = e;
    // console.log(cityName, e);

    let selectedCity = this.cities.find(
      (city) => (
        city.name.toLocaleLowerCase() == cityName.toLocaleLowerCase() ||
        city.name.toLocaleUpperCase() == cityName.toLocaleUpperCase() ||
        city.name == cityName
      )
    );
    this.onSelectCity.emit(selectedCity);
  }
}
