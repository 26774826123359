import { Injectable } from '@angular/core';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import { OrwiTables } from 'src/app/services/dto/orwi-tables-model';

import { OrwiService } from 'src/app/services/orwi.service';
import { requestList } from 'src/app/services/request-list';
import { OrwiTableStore } from './orwi-table-store';

@Injectable({
  providedIn: 'root',
})
export class OrwiTableService {
  constructor(
    private orwiService: OrwiService,
    private OrwiTableStore: OrwiTableStore
  ) {}

  selectedTable: OrwiTables
  getOrwiTables(): Promise<OrwiTables> {
    const activeStore = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
        debugger
      return this.orwiService
        .serviceRequestPromise('/api/store/getStoreTables', {
          id: activeStore,
        })
        .then((o: { response: OrwiTables; error?: any }) => {
          if (o.response) {
            this.OrwiTableStore.update({
              orwiTableGroups: o.response.groups,
              orwiTables: o.response.tables,
            });
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }


  saveStoreTable(table: Partial<OrwiTables>): Promise<any> {
    const activeStore = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise('/api/store/saveStoreTable', {
          ...table,
          storeId: activeStore,
        })
        .then((o: any) => {
          if (o.response) {
            this.OrwiTableStore.update(({ orwiTables }) => ({
              orwiTables: arrayUpsert(orwiTables, o.response.id, o.response),
            }));
            return resolve(o.response);
          }

          return reject(o?.error);
        });
    });
  }

  linkTable(tableId, code) {
    const activeStore = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise('/api/store/linkTableToCode', {
          id: activeStore,
          tableId: tableId,
          code: code,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  unlinkTable(tableId) {
    const activeStore = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise('/api/store/unlinkTable', {
          id: activeStore,
          tableId: tableId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

//   saveTableGroup(tableGroup: TableGroup): Promise<any> {
//     const activeStore = localStorage.getItem('activeStore');

//     return new Promise((resolve, reject) => {
//       return this.orwiService
//         .serviceRequestPromise(requestList.pos.table.saveTableGroup, {
//           ...tableGroup,
//           storeId: activeStore,
//         })
//         .then((o: any) => {
//           if (o.response) {
//             this.tableStore.update(({ tableGroups }) => ({
//               tableGroups: arrayUpsert(tableGroups, o.response.id, o.response),
//             }));
//             return resolve(o.response);
//           }

//           return reject(o?.error?.code);
//         });
//     });
//   }

//   deleteTable(id: string) {
//     const storeId = localStorage.getItem('activeStore');

//     return new Promise((resolve, reject) => {
//       return this.orwiService
//         .serviceRequestPromise(requestList.pos.table.deleteTable, {
//           id,
//           storeId,
//         })
//         .then((o: any) => {
//           if (o.response) {
//             this.tableStore.update(({ tables }) => ({
//               tables: arrayRemove(tables, id),
//             }));
//             return resolve(o.response);
//           }

//           return reject(o?.error?.code);
//         });
//     });
//   }

//   deleteTableGroup(id: string) {
//     const storeId = localStorage.getItem('activeStore');

//     return new Promise((resolve, reject) => {
//       return this.orwiService
//         .serviceRequestPromise(requestList.pos.table.deleteTableGroup, {
//           id,
//           storeId,
//         })
//         .then((o: any) => {
//           if (o.response) {
//             this.tableStore.update(({ tableGroups }) => ({
//               tableGroups: arrayRemove(tableGroups, id),
//             }));
//             return resolve(o.response);
//           }

//           return reject(o?.error?.code);
//         });
//     });
//   }
}
