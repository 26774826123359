import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { OrwiStore, StoreStaff, UploadImage } from 'src/app/services/dto/store-model';

export interface OrwiStoreState {
  ownedStore: OrwiStore[];
  activeStore: OrwiStore;
  staffs: StoreStaff[];
  categories: [];
  bannerImages: UploadImage[];
  popupImages: UploadImage[];
}

export function createInitialState(): OrwiStoreState {
  return {
    ownedStore: [],
    activeStore: new OrwiStore(),
    staffs: [],
    categories: [],
    bannerImages: [],
    popupImages: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'owned-stores',resettable: true })
export class OrwiStoreStore extends Store<OrwiStoreState> {
  constructor() {
    super(createInitialState());
  }
}
