const mainDirPrefix = '/main/';
const definitionsDirPrefix = mainDirPrefix + 'definitions/';
const specialPrefix = mainDirPrefix + 'special-apps/';


export const sidebarMenu: Menu[] = [
  
  {
    id: 'report-dashboard',
    title: 'report dashboard',
    active: false,
    icon: 'receipt_long',
    path: mainDirPrefix + 'report-dashboard',
    tag: 'report-dashboard',
  },
  
  {
    id: 'owned-stores',
    title: 'owned stores',
    active: false,
    icon: 'store_mall_directory',
    tag: 'owned-stores',
    path: mainDirPrefix + 'owned-stores'
  },
  {
    id: 'orwi-dukkan',
    title: 'Orwi Dükkan',
    active: false,
    icon: 'store_mall_directory',
    tag: 'orwi-dukkan',
    children: [
      {
        id: 'home',
        title: 'Orwi Dashboard',
        active: false,
        icon: 'other_houses',
        path: mainDirPrefix + 'orwi',
        tag: 'orwi',
      },
      {
        id: 'campaing',
        title: 'campaign management',
        active: false,
        icon: 'confirmation_number',
        path: mainDirPrefix + 'campaign-management',
        tag: 'campaing',
      },
      {
        id: 'orwi-coupon-management',
        title: 'Orwi Coupon Management',
        active: false,
        icon: 'receipt_long',
        path: mainDirPrefix + 'orwi-coupon-management',
        tag: 'orwi-coupon-management',
      },
      {
        id: 'home',
        title: 'Orwi Folios',
        active: false,
        icon: 'receipt_long',
        path: mainDirPrefix + 'orwi-folios',
        tag: 'orwi-folios',
      },
      {
        id: 'folio-report',
        title: 'Orwi Folio Report',
        active: false,
        icon: 'receipt_long',
        path: mainDirPrefix + 'orwi-folio-report',
        tag: 'orwi-folio-report',
      },
      {
        id: 'faq',
        title: 'frequently asked questions',
        active: false,
        icon: 'quiz',
        path: mainDirPrefix + 'frequently-asked-questions',
        tag: 'store',
      },
      {
        id: 'announcements',
        title: 'announcements/offers',
        active: false,
        icon: 'campaign',
        path: mainDirPrefix + 'announcements',
        tag: 'announcements',
      },
      {
        id: 'orwitables',
        title: 'Orwi Tables',
        active: false,
        icon: 'campaign',
        path: mainDirPrefix + 'orwi-tables',
        tag: 'announcements',
      },
      {
        id: 'feedbacks',
        title: 'Feedbacks',
        active: false,
        icon: 'feedback',
        path: mainDirPrefix + 'report-feedback',
        tag: 'feedbacks',
      },
      {
        id: 'customer-list',
        title: 'Customer List',
        active: false,
        icon: 'users',
        path: mainDirPrefix + 'customer-list',
        tag: 'customer-list',
      },
      {
        id: 'survey-list',
        title: 'Survey',
        active: false,
        icon: 'users',
        path: mainDirPrefix + 'survey-list',
        tag: 'survey-list',
      },
      {
        id: 'survey-form-list',
        title: 'Survey Form List',
        active: false,
        icon: 'file-tray-stacked-outline',
        path: mainDirPrefix + 'survey/list',
        tag: 'survey-form-list',
      },
      {
        id: 'reservation-points-list',
        title: 'Reservation Points',
        active: false,
        icon: 'file-tray-stacked-outline',
        path: mainDirPrefix + 'ticket-points/list',
        tag: 'reservation-points-list',
      },
    ],
  },
  {
    id: 'orwi-pos-reports',
    title: 'Orwi Pos Reports',
    active: false,
    icon: 'receipt_long',
    tag: 'orwi-pos-reports',
    children: [
      {
        id: 'home',
        title: 'Product Sales Report',
        active: false,
        icon: 'other_houses',
        path: mainDirPrefix + 'orwi-pos-reports',
        tag: 'reports',
      }
    ],
  },
  {
    id: 'menu',
    title: 'product and product groups',
    active: false,
    icon: 'shopping_basket',
    tag: 'menu',
    children: [
      {
        id: 'menu',
        title: 'add product or product group',
        active: false,
        icon: '',
        path: mainDirPrefix + 'product-and-product-groups/list',
        tag: 'group',
      },
      {
        id: 'menu',
        title: 'variation',
        active: false,
        icon: '',
        path: mainDirPrefix + 'variation',
        tag: 'variation',
      },
      {
        id: 'menu',
        title: 'suggestions',
        active: false,
        icon: '',
        path: mainDirPrefix + 'suggestions',
        tag: 'suggestions',
      },
      {
        id: 'menu',
        title: 'Product Suggestions',
        active: false,
        icon: '',
        path: mainDirPrefix + 'product-suggestions',
        tag: 'product-suggestions',
      },
    ],
  },
  {
    id: 'users',
    title: 'personels',
    active: false,
    icon: 'perm_identity',
    path: mainDirPrefix + 'staffs',
    tag: 'users',
  },
  {
    id: 'paytypes',
    title: 'define pay type',
    active: false,
    icon: 'credit_card',
    path: mainDirPrefix + 'payment-type-definition',
    tag: 'paytypes',
  },
  {
    id: 'folioList',
    title: 'folio list',
    active: false,
    icon: 'receipt_long',
    path: mainDirPrefix + 'folio-list',
    tag: 'folioList',
  },
  {
    id: 'orderService',
    title: 'order service',
    active: false,
    icon: 'moped',
    path: mainDirPrefix + 'order-service',
    tag: 'orderService',
  },
  {
    id: 'orderTracking',
    title: 'order tracking',
    active: false,
    icon: 'restaurant',
    path: mainDirPrefix + 'order-tracking',
    tag: 'orderTracking',
  },
  {
    id: 'definations',
    title: 'definitions',
    active: false,
    icon: 'iso',
    tag: 'definitions',
    children: [
      {
        id: 'definations',
        title: 'discount definitions',
        active: false,
        icon: '',
        path: definitionsDirPrefix + 'discount',
        tag: 'discountDefinations',
      },
      {
        id: 'definations',
        title: 'printer definitions',
        active: false,
        icon: '',
        path: definitionsDirPrefix + 'printer',
        tag: 'printerDefinations',
      },
      {
        id: 'definations',
        title: 'gift definitions',
        active: false,
        icon: '',
        path: definitionsDirPrefix + 'gift',
        tag: 'giftDefinations',
      },
      {
        id: 'definations',
        title: 'delete product definitions',
        active: false,
        icon: '',
        path: definitionsDirPrefix + 'delete-product',
        tag: 'deleteItemDefinations',
      },
      {
        id: 'definations',
        title: 'tax definitions',
        active: false,
        icon: '',
        path: definitionsDirPrefix + 'tax-rates',
        tag: 'taxDefinations',
      },
      {
        id: 'definations',
        title: 'department definitions',
        active: false,
        icon: '',
        path: definitionsDirPrefix + 'department',
        tag: 'departmentDefinations',
      },
    ],
  },
  {
    id: 'tablesDefinations',
    title: 'table definitions',
    active: false,
    icon: 'space_dashboard',
    path: mainDirPrefix + 'table-and-groups',
    tag: 'tablesDefinations',
  },
  {
    id: 'couponManagement',
    title: 'coupon management',
    active: false,
    icon: 'confirmation_number',
    path: mainDirPrefix + 'coupon-management',
    tag: 'couponManagement',
  },
  {
    id: 'integration',
    title: 'integration',
    active: false,
    icon: 'device_hub',
    path: mainDirPrefix + 'integration',
    tag: 'integration',
  },
  {
    id: 'store',
    title: 'store settings',
    active: false,
    icon: 'store_mall_directory',
    path: mainDirPrefix + 'store-settings',
    tag: 'store',
  },
  {
    id: 'legal-notes',
    title: 'legal notes',
    active: false,
    icon: 'description',
    path: mainDirPrefix + 'legal-notes',
    tag: 'legal-notes',
  },
  {
    id: 'link-table',
    title: 'Link Table',
    active: false,
    icon: 'table_restaurant',
    tag: 'link-table',
    path: mainDirPrefix + 'link-table'
  },
  {
    id: 'assign-qr',
    title: 'Assign Qr',
    active: false,
    icon: 'qr_code',
    tag: 'assign-qr',
    state: {
      fromApp: true
    },
    path: mainDirPrefix + 'assign-qr-code'
  },
  {
    id: 'reservations',
    title: 'reservations',
    active: false,
    icon: 'alarm',
    path: mainDirPrefix + 'reservations',
    tag: 'reservations',
  },
  {
    id: 'logout',
    title: 'logout',
    active: false,
    icon: 'logout',
    path: '',
    tag: 'logout',
  },
];

export interface Menu {
  id?: number | string;
  title?: string;
  path?: string;
  icon?: string;
  active?: boolean;
  tag?: string;
  children?: Menu[];
  state?: any;
}
