import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { ActionSheetOptions } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { AlertOptions } from '@ionic/core/dist/types/components/alert/alert-interface';
import { ModalOptions } from '@ionic/core/dist/types/components/modal/modal-interface';
import { Clipboard } from '@capacitor/clipboard';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    public modalController: ModalController,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    public popoverController: PopoverController,
    private transloco: TranslocoService
  ) {}

  private daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  private tahsilatTipleri = [
    {
      value: 'cash',
      name: 'Nakit',
    },
    {
      value: 'not-paid',
      name: 'Ödenmez',
    },
    {
      value: 'account',
      name: 'Cari',
    },
    {
      value: 'bank',
      name: 'Banka',
    },
    {
      value: 'staff',
      name: 'Personel',
    },
    {
      value: 'meal-voucher',
      name: 'Yemek Çeki',
    },
    {
      value: 'external',
      name: 'Harici',
    },
    {
      value: 'bank-transfer',
      name: 'Havale',
    },
    {
      value: 'other',
      name: 'Diğer',
    },
  ];

  private PRINTER_TYPES = ['unit', 'folio', 'invoice', 'other'];

  private SERVICE_TYPES = [
    'self-ordering',
    'self',
    'table',
    'delivery',
    'take-away',
    'timed-take-away',
    'timed-table',
  ];

  get serviceTypes() {
    return this.SERVICE_TYPES;
  }
  get printerTypes() {
    return this.PRINTER_TYPES;
  }

  get TahsilatTypes() {
    return this.tahsilatTipleri;
  }

  get days() {
    return this.daysOfWeek;
  }

  writeToClipboard = async (text: string) => {
    await Clipboard.write({
      string: text,
    });
  };

  formatPhone(phone: string) {
    let formatted = phone.split(' ').join('');
    formatted = formatted.replace('(', '');
    formatted = formatted.replace(')', '');
    let activeDialCode = localStorage.getItem('activeDialCode') || '+90';
    //if(activeDialCode.endsWith('0')) activeDialCode = activeDialCode.slice(0,-1);
    return activeDialCode + formatted;
  }

  getUnFormattedPhone(phone: string) {
    let activeDialCode = localStorage.getItem('activeDialCode') || '+90';
    if(activeDialCode.endsWith('0')) activeDialCode = activeDialCode.slice(0,-1);

    return phone.slice(activeDialCode.length);
  }

  isMobile() {
    let width = window.innerWidth;
    return width <= 425;
  }

  filterData(data = [], term = '', searchKey = '') {
    if (term) {
      data = data.filter(
        (elm) =>
          String(elm[searchKey]).indexOf(term) >= 0 ||
          String(elm[searchKey])
            .toLocaleLowerCase()
            .indexOf(String(term).toLocaleLowerCase()) >= 0
      );
    } else {
      data = data;
    }
    return data;
  }

  deleteNull(data: {}) {
    let formatted = {};
    Object.keys(data).forEach((elm) => {
      if (!data[elm]) delete data[elm];
      else formatted[elm] = data[elm];
    });

    return formatted;
  }

  async openModal(
    component,
    props = {},
    className = 'my-custom-class',
    backDrop = false,
    options: Partial<ModalOptions> = {}
  ) {
    const modal = await this.modalController.create({
      component: component,
      cssClass: className,
      backdropDismiss: backDrop,
      componentProps: props,
      ...options
    });
    await modal.present();
    return modal;
  }

  closeModal(data?: any, role?: string) {
    this.modalController.dismiss(data, role);
  }

  async openActionSheet(config: ActionSheetOptions) {
    const actionSheet = await this.actionSheetController.create(config);
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    return role;
  }

  async openAlert(config: AlertOptions) {
    config.header = this.transloco.translate(config.header)
    config.subHeader = this.transloco.translate(config.subHeader)
    const alert = await this.alertController.create(config);

    await alert.present();

    return alert;
  }

  async openPopOver(event, component, props = {}) {
    const popover = await this.popoverController.create({
      component: component,
      componentProps: props,
      event: event,
      cssClass: 'pop-over-clazz',
      translucent: true,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

  getError(cName: string, form: FormGroup | AbstractControl, errorType: string = 'required') {
    return form.get(cName)?.hasError(errorType) && form.get(cName)?.touched;
  }
}
