import { CONTEXT_NAME } from '@angular/compiler/src/render3/view/util';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { City, Town } from 'src/app/services/dto/general-model';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-town-selectbox',
  templateUrl: './town-selectbox.component.html',
  styleUrls: ['./town-selectbox.component.scss'],
})
export class TownSelectboxComponent implements OnInit,OnChanges {

  @Input()
  city: City;

  @Output()
  onSelectTown = new EventEmitter();

  @Input()
  set selectedTown(e) {
    this._selectedTown = e?.toLocaleUpperCase();
  }


  _selectedTown: string = '';

  towns: Town[] = [];
  
  @Output()
  loaded = new EventEmitter();

  constructor(private generalService: GeneralService) {
  
   }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if(changes?.city && changes?.city?.currentValue?.name !== changes?.city?.previousValue?.name) {
       // this.onSelectTown.emit(null);
    }
    if(changes?.city) {
      this.getTowns();
    }
  }

  ngOnInit() {
    // console.log(this.city)
    this.getTowns();
    
  }

  getTowns() {
    if(this.city) {
      this.generalService.getTowns(this.city).then(o => {
        this.towns = o;
        this.loaded.emit(true);
      })
    }
  }

  selectTown(e) {
    this.onSelectTown.emit(e);
  }
}
