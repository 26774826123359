import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';
import { OrwiStoreStore } from 'src/app/store/orwi/orwi-store.store';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private orwiStoreStore: OrwiStoreStore,
    private os: OrwiStoreService,
    private glb: GlobalService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = req.url;
    let apiKey = 'YWxpLTEyMzQ1MTEyNDU2NTQzMg';
    // if (url.includes('api/faq/') || url.includes('api/feed/')) {
    //   if (req.body.specialApps) {
    //     delete req.body.specialApps;
    //     if (store.apiKey) {
    //       apiKey = store.apiKey;
    //     }
    //   }
    // }

    let user = localStorage.getItem('userInfoPortal');
    let token = {};
    if (user) {
      token['token'] = JSON.parse(user)['token'];
    }

    if (this.getApiKeyForDomain()) {
      apiKey = this.getApiKeyForDomain();
    }

    let store = this.orwiStoreStore.getValue().activeStore;
    let usedHeader: any = {};
    let cdnHeader = {
      'x-api-key': 'VkV2VtUkRJRVp6WVZOQ',
      // 'Content-Type': 'multipart/form-data',
      'external-id': req.headers.get('external-id'),
    };
    let header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      apikey: apiKey,
    };
    if (url.includes(environment.cdnService)) {
      usedHeader = cdnHeader;
    } else {
      usedHeader = header;
      //  usedHeader.apikey=this.domains.bodrum;
    }

    const newReq = req.clone({
      setHeaders: {
        ...usedHeader,
        ...token,
        language: 'en',
        region: 'us',
      },
    });
    return next.handle(newReq);
  }

  getApiKeyForDomain(): string | undefined {
    let subDomain = window.location.hostname.split('.')[0];
     // subDomain = 'bigchefs'
    let apiKey = this.domains[subDomain];
    this.glb.dbName = this.dbNames[subDomain];
    return apiKey;
  }

  domains = {
    numnum:
      'TldZeE5UbGpaVFZrWjIzZmYzMmYyM2YycTNmbVZpWmpFd09UVmlOekJsWmpBMHNkZjMycmYyZjNmMmYyM2Y',
    beltur:
      'V1cxV2MweFlVakZqYlhld3dlcndlZmhyV20xMGMyRXpUbWhhUnpWdFlXNWtiVnBZWkd4a2JWWnR3cQ',
    bodrum: 'Ym9kdW0tYmVsLTMyNDgyxLFmajMyNDk4dDNnNGg5xLE4NDNoZ3bEsXV1OiE',
    mackbear: 'bWFjLWJlYXItNDE1MjNmeGQyM2VyMmZlYzIg',
    main: 'YWxpLTEyMzQ1MTEyNDU2NTQzMg',
    bigchefs: 'V1cxc2Jsa3lhR3F3ZXF3ZXF3ZXhhYmsxMFdWaENkMHhYZEd4bFVR',
    cm: 'V1RJNWJWcHRWbVJrWkdSellYTmtZWE5zWWxkR2RXRlhSWFJaV0VKM1pHUQ',
    understone : '8xwV2DwZ6zP1GNIm9XzlLx2PYSLDyp1Y70XkrD0ugUQa',
  };

  dbNames = {
    numnum: 'numnum-app',
    beltur: 'beltur-app',
    bodrum: 'bodrum-app',
    mackbear: 'mackbear-app',
    main: 'orwi-prod',
    localhost: 'orwi-prod',
    bigchefs: 'bigchefs-app',
    cm: 'coffeemania-app',
    understone : 'understone-app'
  };
}
