import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-orwi-select',
  templateUrl: './orwi-select.component.html',
  styleUrls: ['./orwi-select.component.scss'],
})
export class OrwiSelectComponent {
  @Input('options')
  set _options(e: any) {
    this.Options = {
      ...this.Options,
      ...e,
    };
    if (this.Options.value) {
      this.selectedRows = this.convertIdsToObjects();
    }
  }

  @Input('items')
  set list(e) {
    this._options = {
      ...this.Options,
      list: e
    }
  }

  get _Options() {
    return this.Options;
  }

  private Options: Partial<OrwiSelectOptions<any>> = {
    multiple: false,
    searchable: true,
    list: [],
  };

  isOpen = false;

  selectedRows = [];
  selectionChangedHandler() {
    // console.log(this.selectedRows);
  }

  constructor() {}

  cancel() {
    this.selectedRows = this.convertIdsToObjects();
    this.isOpen = false;
  }

  save() {
    if (this.Options.multiple) {
      this.handleMultipleSelection();
    } else if (this.Options.multiple == false) {
      this.handleSingleSelection();
    }

    this.isOpen = false;
  }

  handleMultipleSelection() {
    this.Options.value = this.generateIds(this.selectedRows);

    this.Options.onSelected(this.selectedRows, this.Options.value);
  }

  handleSingleSelection() {
    this.Options.value = this.generateIds(this.selectedRows)?.[0];

    this.Options.onSelected(this.selectedRows?.[0], this.Options.value as any);
  }

  private convertIdsToObjects() {
    return this.Options.list.filter((el) =>
      Array.isArray(this.Options.value)
        ? (this.Options.value as any).includes(el[this.Options.bindValue])
        : el[this.Options.bindValue] == this.Options.value
    );
  }

  generateLabel() {
    return this.convertIdsToObjects()
      .map((el) =>
        this.Options.bindLabel.split('.').reduce((obj, key) => obj?.[key], el)
      )
      .join(',');
  }

  private generateIds(array: any[]) {
    return array.map((el) =>
      this.Options.bindValue.split('.').reduce((obj, key) => obj?.[key], el)
    );
  }
}

export type OrwiSelectOptions<T> = {
  list?: T[];
  columns: OrwiSelectColumns<T>[];
  searchable?: boolean;
  bindLabel: string;
  bindValue: string;
  title?: string;
} & (
  | {
      multiple: true;
      value?: string[] | number[];
      onSelected?: (data: T[], bindValues: any[]) => void;
    }
  | {
      multiple: false;
      value?: string | number;
      onSelected?: (data: T, bindValues: any) => void;
    }
);

export type OrwiSelectColumns<T> = {
  datafield: keyof T | string;
  caption: string;
};
