import { Component, Input, OnInit } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import { CdnService } from 'src/app/services/cdn.service';
import { ImageTypes, imageItemTypes } from 'src/app/services/dto/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
  // @Input()
  // icon: string = 'file_upload';

  // @Input()
  // title: string = 'Resim Yükle';

  // @Input()
  // subtitle: string = '400x-400x';

  // @Input()
  // itemType: ImageTypes = 'store';

  // @Input()
  // itemid: string;

  // @Input()
  // imageSize: number = 400;

  options: ImageUploadConfig = {
    icon: 'file_upload',
    title: 'upload picture',
    subtitle: '400x-400x',
    itemType: 'store',
    itemid: null,
    imageSize: '400',
  };

  imageFormData = new FormData();
  base64:string;

  @Input()
  config: ImageUploadConfig = {};

  constructor(
    private helper: HelperService,
    private glb: GlobalService,
    private orwiStoreService: OrwiStoreService,
    private cdn : CdnService
  ) {}

  ngOnInit() {
    this.options = {
      ...this.options,
      ...this.config,
    };
  }

  relocate(elm : HTMLInputElement){
    if (this.config.onClick) {
      return this.config.onClick();
    }
    else{
      elm.click();
    }
  }

  async pickImage(imgInput : HTMLInputElement) {
    // const image = await Camera.getPhoto({
    //   quality: 90,
    //   allowEditing: false,
    //   resultType: CameraResultType.Base64,
    //   promptLabelPhoto: 'Galeriden Seç',
    //   promptLabelPicture: 'Fotoğraf Çek',
    //   promptLabelHeader: 'Resim',
    //   promptLabelCancel: 'İptal Et ',
    // });

    //let base64 = 'data:image/' + image.format + ';base64,' + image.base64String;
    // if (this.config.customUpload) {
    //   return this.config.customUpload(base64);
    // }

    if (imgInput.files.length > 0) {
      this.imageFormData.delete('file');
      this.imageFormData.append('file', imgInput.files[0]);
      this.uploadImage();
    }
  }

  uploadImage() {
    this.helper.openAlert({
      header: 'Resim Yükleme',
      subHeader: 'Seçtiğiniz Resmi Yüklemek ister misiniz?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Evet',
          handler: () => {
            this.glb.showLoading();
        
            this.cdn
              .uploadImage(this.imageFormData, this.config.orwiQrId).toPromise()
              .then(
                (o: any) => {
                  let imageUrl = o.message;
                  this.base64 = imageUrl;
                  if (this.config.customUpload) {
                    console.log('config içinde', this.base64)
                    return this.config.customUpload(this.base64);
                  }
                  if (this.config.onImagePicked) {
                    console.log('config içinde', this.base64)
                    return this.config.onImagePicked(this.base64);
                  }
                },
                (err) => {
                  this.glb.toast('Bir Sorun Oluştu', '', 'bottom', 'danger');
                  this.glb.closeLoading();
                  setTimeout(() => {
                    this.glb.closeLoading();
                  }, 1000);
                }
              ).then((o) => {
                this.glb.toast(
                  'Başarıyla Güncellendi!',
                  '',
                  'bottom',
                  'success'
                );
                this.glb.closeLoading();
                setTimeout(() => {
                  this.glb.closeLoading();
                }, 1000);
              });
          },
        },
        {
          text: 'Hayır',
        },
      ],
    });
  }



}

export interface ImageUploadConfig {
  icon?: string;
  title?: string;
  subtitle?: string;
  itemType?: ImageTypes;
  itemid?: string;
  imageSize?: string;
  onImagePicked?: (base64) => void;
  onClick?: () => void;
  customUpload?: (base64: string) => void;
  orwiQrId?: string;
}
