import { Injectable } from '@angular/core';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import { LegalNote } from 'src/app/services/dto/legal-notes-model';
import { OrwiService } from 'src/app/services/orwi.service';
import { requestList } from 'src/app/services/request-list';
import { LegalNoteStore } from './legal-note.store';

@Injectable({
  providedIn: 'root',
})
export class LegalNoteService {
  constructor(
    private orwiService: OrwiService,
    private legalNoteStore: LegalNoteStore
  ) {}

  getAll(): Promise<LegalNote[]> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.legalNotes.getLegalNotes, {
          storeId,
        })
        .then((o: any) => {
          if (o.response) {
            this.legalNoteStore.update({
              legalNotes: o.response,
            });
            return resolve(Object.assign(o).response);
          }
          return reject(o?.error?.code);
        });
    });
  }

  addLegalNote(legalNote: LegalNote) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.legalNotes.saveLegalNote, {
          ...legalNote,
        })
        .then((o: any) => {
          if (o.response) {
            this.legalNoteStore.update(({ legalNotes }) => ({
              legalNotes: arrayUpsert(legalNotes, o.response.id, o.response),
            }));
            return resolve(Object.assign(o).response);
          }
          return reject(o?.error?.code);
        });
    });
  }

  deleteLegalNote(id: string) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(requestList.legalNotes.deleteLegalNote, { id })
        .then((o: any) => {
          if (o.response) {
            this.legalNoteStore.update(({ legalNotes }) => ({
              legalNotes: arrayRemove(legalNotes, id),
            }));
            return resolve(Object.assign(o).response);
          }
          return reject(o?.error?.code);
        });
    });
  }
}
