export interface LegalNote {
  id: string;
  caption: string;
  detail: string;
  type: LegalNotesType;
  createDate: Date;
  lastEditDate: Date;
  lang: string;
}

type LegalNotesType =
  | 'express-consent'
  | 'membership-agreement'
  | 'privacy-policy'
  | 'clarification-text'
  | 'data-owner-form';

export const LegalNotesTypes = [
  'express-consent',
  'membership-agreement',
  'privacy-policy',
  'clarification-text',
  'data-owner-form',
  'cookie-policy'
];
