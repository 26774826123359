import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";


@Injectable()
export default class OffLineGuard implements CanActivate {

    constructor(private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): boolean {

        let isLogin = localStorage.getItem('userInfoPortal');
        if(!isLogin) {
            return true;
        }
        this.router.navigateByUrl('/main');
      }

}