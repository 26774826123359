import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss'],
})
export class OtpFormComponent implements OnInit {
  timerId;
  constructor(
    private router: Router,
    private modalController: ModalController,
    private fb: FormBuilder,
    private userService: UserService,
    private helper: HelperService,
    private glb: GlobalService,
    private transloco: TranslocoService
  ) {}

  @Input()
  gsm: string;

  @Input()
  isRegister: boolean = false;

  form = this.fb.group({
    otp: [null, Validators.required],
  });

  get otpFormControl() {
    return this.form.get('otp') as FormControl;
  }
  count = 60;

  ngOnInit() {
    this.startCounter();
  }

  startCounter() {
    this.timerId = setInterval(() => {
      this.count--;
      if (this.count <= 0) clearInterval(this.timerId);
    }, 1000);
  }

  reSend() {
    if (this.count == 0) {
      this.count = 10;
      this.startCounter();
      this.verifyOtp();
    }
  }

  ionViewWillLeave() {
    clearInterval(this.timerId);
    this.count = 60;
  }

  verifyOtp() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.glb.showLoading(
        this.transloco.translate('Checking SMS Password') + '...'
      );
      this.userService.verifyOtp(this.gsm, this.form.controls.otp.value).then(
        (o: any) => {
          this.glb.closeLoading();
          this.modalController.dismiss({
            userData: o,
          });

          if (!this.isRegister) {
            this.router.navigate(['/main'], { replaceUrl: true });
          }
        },
        (e) => {
          if (e == 'otp_invalid') {
            this.glb.closeLoading();
            this.glb.toast(
              this.transloco.translate('Sms Code Invalid'),
              '',
              'bottom',
              'danger'
            );
            this.form.reset();
          }
        }
      );
    }
  }

  close() {
    this.modalController.dismiss();
  }
}
