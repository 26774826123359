import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/services/helpers/helper.service';

@Component({
  selector: 'app-staff-permission-modal',
  templateUrl: './staff-permission-modal.component.html',
  styleUrls: ['./staff-permission-modal.component.scss'],
})
export class StaffPermissionModalComponent implements OnInit {
  staffId: string;
  constructor(private fb: FormBuilder,public helper: HelperService) {}

  form = this.fb.group({
    generalPerms: [],
    perms: [],
  });

  ngOnInit() {
    console.log(this.staffId);
  }

  selectedSegment = 'genel';
}
