import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import config from 'devextreme/core/config';

@Component({
  selector: 'app-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.scss'],
})
export class SelectLangComponent implements OnInit {
  @Input()
  isLoginScreen = false;

  currentLang = 'en';

  cancelText = '';

  langs = this.service.getAvailableLangs() as string[];

  currency = {
    en: 'USD',
    tr: 'TRY',
    de: 'EUR',
    nl: 'EUR',
  };

  customPopoverOptions: any = {
    header: this.service.translate('Select Language'),
  };

  constructor(private router: Router, private service: TranslocoService) {}

  

  ngOnInit() {
    this.service.setActiveLang(localStorage.getItem('lang') ?? 'en');

    this.currentLang = this.service.getActiveLang();
    this.service
      .selectTranslate('cancel')
      .subscribe((data) => (this.cancelText = data));
    config({
      defaultCurrency: 'TRY',
    });
  }
  selectLang(e: any) {
    const lang = e;
    this.service.setActiveLang(lang);
    localStorage.setItem('lang', lang);

    this.currentLang = this.service.getActiveLang();

    config({
      defaultCurrency: 'TRY',
    });
  }

}
