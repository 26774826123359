import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrwiStore } from 'src/app/services/dto/store-model';
import { UserOwnedStore } from 'src/app/services/dto/user-model';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-store-list-modal',
  templateUrl: './store-list-modal.component.html',
  styleUrls: ['./store-list-modal.component.scss'],
})
export class StoreListModalComponent implements OnInit {

  
  constructor(private modalController: ModalController) {
    let store = localStorage.getItem('activeStore');
    if (store) this.hasStore = true;
    else this.hasStore = false;
  }

  hasStore = true;

  ngOnInit() {}
  changeActiveStore= true

  selectStore(store: any) {
    this.modalController.dismiss({ store: store });
  }

  close() {
    this.modalController.dismiss();
  }
}
