import { Component, OnInit } from '@angular/core';
import { KerzzButtonComponent } from '../button.base';

@Component({
  selector: 'app-create-button',
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.scss'],
})
export class CreateButtonComponent extends KerzzButtonComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {}

}
