import { Injectable } from '@angular/core';
import { City, Country, Town } from './dto/general-model';
import { OrwiService } from './orwi.service';
import { requestList } from './request-list';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private orwiService: OrwiService) { }


  get Countries(): Promise<Country[]> {
    return new Promise((resolve,reject) => {
      return this.orwiService.serviceRequestPromise(requestList.helper.getCountries,{}).then((o: any) => {
        if(o.response) {
          return resolve(o.response);
        }

        return reject(o?.error?.code || 'Bir Sorun Oluştu');
      })
    })
  }

  getCities(countryCode: string): Promise<City[]> {
    return new Promise((resolve,reject) => {
      return this.orwiService.serviceRequestPromise(requestList.helper.getCities,{
        countryCode:countryCode ,
      }).then((o: any) => {
        if(o.response) {
          return resolve(o.response);
        }

        return reject(o?.error?.code || 'Bir Sorun Oluştu');
      })
    })
  }
  
  getTowns(city: City): Promise<Town[]> {
    return new Promise((resolve,reject) => {
      return this.orwiService.serviceRequestPromise(requestList.helper.getTowns,{
        countryCode: city.countryCode ,
        cityCode: city.code
      }).then((o: any) => {
        if(o.response) {
          return resolve(o.response);
        }

        return reject(o?.error?.code || 'Bir Sorun Oluştu');
      })
    })
  }
}
