import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  private searchForm = new BehaviorSubject('');
  search$ = this.searchForm.asObservable();

  private buttonGroup = new BehaviorSubject([]);
  buttons$ = this.buttonGroup.asObservable();

  constructor() {}

  set search(e: string) {
    this.searchForm.next(e);
  }

  get searchTerm() {
    return this.search$;
  }

  set buttons(e: any[]) {
    this.buttonGroup.next(e);
  }
}
