import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserStore, UserState } from './user.store';



@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
    token$ = this.select('token')
    userInfo$ = this.select('userInfo')
    ownedStores$ = this.select('ownedStores')
    constructor(protected store: UserStore) {
        super(store);
    }

    
}