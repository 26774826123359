export const requestList = {
  user: {
    requestOtp: '/api/user/requestOtp',
    verifyOtp: '/api/user/verifyOtp',
    autoLogin: '/api/user/autoLogin',
    saveUser: '/api/user/saveUser',
    requestQrCode: '/api/user/requestQrCode',
    sendMessage: '/api/user/sendMessage',
    deleteUser: '/api/user/deleteUser',
    getOwnedStores: '/api/user/getOwnedStores',
    linkOwnedStore: '/api/user/linkOwnedStore',
    unlinkOwnedStore: '/api/user/unlinkOwnedStore',
    createVirtualUser: '/api/user/createVirtualUser',
    saveLog: '/api/user/saveLog',
    getMasterUsers: '/api/user/getMasterUsers',
    createCaptcha: '/api/user/CreateCaptcha',
    verifyCaptcha: '/api/user/verifyCaptcha',

  },
  store: {
    storeInfo: '/api/store/storeInfo',
    syncStoreMenu: '/api/store/syncStoreMenu',
    storeMenu: '/api/store/storeMenu',
    storeCheckIn: '/api/store/storeCheckIn',
    getStoreTables: '/api/store/getStoreTables',
    getStaffList: '/api/store/getStaffList',
    getStores: '/api/store/getStores',
    saveStore: '/api/store/saveStore',
    deleteStore: '/api/store/deleteStore',
    getTableSections: '/api/store/getTableSections',
    saveTableSection: '/api/store/saveTableSection',
    getDeliveryQr: '/api/store/getDeliveryQr',
    saveStoreTable: '/api/store/saveStoreTable',
    getChildStores: '/api/store/getChildStores',
    getStoreStatus: '/api/store/getStoreStatus',
  },
  image: {
    productGroups: '/api/image/productGroups',
    product: '/api/image/product',
    storeBanners: '/api/image/storeBanners',
    popupBanners: '/api/image/popupBanners',
    menuBanners: '/api/image/menuBanners',
    addressBanners: '/api/image/addressBanners',
    saveImage: '/api/image/saveImage',
    deleteImage: '/api/image/deleteImage',
    imageStore: '/api/image/imageStore',
    coupon: '/api/image/coupon',
  },
  'credit-card': {
    saveCards: '/api/credit-card/saveCards',
    getCardList: '/api/credit-card/getCardList',
    setDefault: '/api/credit-card/setDefault',
    getTransactions: '/api/credit-card/getTransactions',
  },
  legalNotes: {
    getLegalNotes: '/api/legal-notes/getLegalNotes',
    saveLegalNote: '/api/legal-notes/saveLegalNote',
    deleteLegalNote: '/api/legal-notes/deleteLegalNote',
  },
  folio: {
    getFolio: '/api/folio/getFolio',
    saveFolio: '/api/folio/saveFolio',
    closeFolio: '/api/folio/closeFolio',
    doneFolio: '/api/folio/doneFolio',
    lockFolio: '/api/folio/lockFolio',
    saveFolioFromKerzz: '/api/folio/saveFolioFromKerzz',
    refreshFolio: '/api/folio/refreshFolio',
    cancelFolio: '/api/folio/cancelFolio',
    callWaiter: '/api/folio/callWaiter',
    requestCheck: '/api/folio/requestCheck',
    getFoliosByStore: '/api/folio/getFoliosByStore',
    cancelFolioFromKerzz: '/api/folio/cancelFolioFromKerzz',
    getFoliosByDate: '/api/pos/folio/getAllFoliosByDate',
    getReportFolio: '/api/report/getReportFolios',
    getFolioIdByUserQr: '/api/folio/getFolioIdByUserQr',
    getUserFolios: '/api/folio/getUserFolios',
    getFolioById: '/api/folio/getFolioById',
    saveFolioFromPOS: '/api/folio/saveFolioFromPOS',
    cancelFolioFromPOS: '/api/folio/cancelFolioFromPOS',
    hideFolioFromPOS: '/api/folio/hideFolioFromPOS',
    reciveFolioFromPOS: '/api/folio/reciveFolioFromPOS',
    controlFolioFromPOS: '/api/folio/controlFolioFromPOS',
    getDeliveryFoliosOnOwnedStores: '/api/folio/getDeliveryFoliosOnOwnedStores',
    updateFolioDeliveryStatus: '/api/folio/updateFolioDeliveryStatus',
    getOrwiFoliosByDate : '/api/folio/getFoliosByDate'
  },

  'user-point': {
    getPoints: '/api/user-point/getPoints',
    addPoint: '/api/user-point/addPoint',
    getStorePointTotals: '/api/user-point/getStorePointTotals',
    calcPointWithFolio: '/api/user-point/calcPointWithFolio',
  },
  device: { register: '/api/device/register' },
  'master-pass': {
    createToken: '/api/master-pass/createToken',
    commitPurchase: '/api/master-pass/commitPurchase',
    getConfigs: '/api/master-pass/getConfigs',
    startPurchase: '/api/master-pass/startPurchase',
    successPurchase: '/api/master-pass/successPurchase',
    commit3dPurchase: '/api/master-pass/commit3dPurchase',
  },
  notification: {
    getNotifications: '/api/notification/getNotifications',
    deleteNotification: '/api/notification/deleteNotification',
    getStaffNotifications: '/api/notification/getStaffNotifications',
    deleteStaffNotification: '/api/notification/deleteStaffNotification',
    getStaffUnreadNotificationCount:
      '/api/notification/getStaffUnreadNotificationCount',
    getUnreadNotificationCount: '/api/notification/getUnreadNotificationCount',
  },
  address: {
    saveAddress: '/api/address/saveAddress',
    getAddresses: '/api/address/getAddresses',
    deleteAddress: '/api/address/deleteAddress',
    saveInvoiceAddress: '/api/address/saveInvoiceAddress',
    getInvoiceAddress: '/api/address/getInvoiceAddress',
    deleteInvoiceAddress: '/api/address/deleteInvoiceAddress',
    getAccountInfo: '/api/address/getAccountInfo',
    getInvoiceAddressById: '/api/address/getInvoiceAddressById',
    getAddressById: '/api/address/getAddressById',
    saveLocationInfo: '/api/address/saveLocationInfo',
  },
  helper: {
    getCountries: '/api/helper/getCountries',
    getCities: '/api/helper/getCities',
    getTowns: '/api/helper/getTowns',
    getAppLinks: '/api/helper/getAppLinks',
    createTableQR: '/api/helper/createTableQR',
    saveFeedback: '/api/helper/saveFeedback',
    getIpAddress: '/api/helper/getIpAddress',
    becomeOrwiFeedback: '/api/helper/becomeOrwiFeedback',
    updateTableQrStoreId: '/api/helper/updateTableQrStoreId',
  },
  rating: {
    saveRatingFolio: '/api/rating/saveRatingFolio',
    saveStoreFeedback: '/api/rating/saveStoreFeedback',
  },
  menu: {
    saveProduct: '/api/menu/saveProduct',
    saveGroup: '/api/menu/saveGroup',
    getProducts: '/api/menu/getProducts',
    getGroups: '/api/menu/getGroups',
    deleteProduct: '/api/menu/deleteProduct',
    deleteGroup: '/api/menu/deleteGroup',
    menuQR: '/api/menu/menuQR',
    setGroupStatus: '/api/menu/setGroupStatus',
    setProductStatus: '/api/menu/setProductStatus',
    getModifiers: '/api/menu/getModifiers',
    saveModifier: '/api/menu/saveModifier',
    deleteModifier: '/api/menu/deleteModifier',
    getAdvices: '/api/menu/getAdvices',
    saveAdvice: '/api/menu/saveAdvice',
    deleteAdvice: '/api/menu/deleteAdvice',
  },
  myPlaces: {
    getMyPlaces: '/api/myPlaces/getMyPlaces',
    deleteMyPlaces: '/api/myPlaces/deleteMyPlaces',
  },
  payoff: {
    getRules: '/api/payoff/getRules',
    saveRule: '/api/payoff/saveRule',
    deleteRule: '/api/payoff/deleteRule',
  },
  coupon: {
    createCoupon: '/api/coupon/createCoupon',
    createScratch: '/api/coupon/createScratch',
    loadCoupon: '/api/coupon/loadCoupon',
    useCouponForPOS: '/api/coupon/useCouponForPOS',
    createCouponForPOS: '/api/coupon/createCouponForPOS',
    getUserCoupons: '/api/coupon/getUserCoupons',
    getCustomCoupons: '/api/coupon/getCustomCoupons',
    getBenefitsForPOS: '/api/coupon/getBenefitsForPOS',
    useBenefitsForPOS: '/api/coupon/useBenefitsForPOS',
    useCoupon: '/api/coupon/useCoupon',
  },
  polygon: {
    savePolygon: '/api/polygon/savePolygon',
    getStorePolygons: '/api/polygon/getStorePolygons',
    deletePolygon: '/api/polygon/deletePolygon',
    controlPolygon: '/api/polygon/controlPolygon',
  },
  report: {
    getReportFolios: '/api/report/getReportFolios',
    getReportStores: '/api/report/getReportStores',
    getReportFolioDetails: '/api/report/getReportFolioDetails',
    getReportFeedbacks: '/api/report/getReportFeedbacks',
    getUserCountStats: '/api/report/getUserCountStats',
    getUserReport: '/api/report/getUserReport',
  },
  contract: {
    getMainContract: '/api/contract/getMainContract',
    saveMainContract: '/api/contract/saveMainContract',
    getStoreContract: '/api/contract/getStoreContract',
    saveStoreContract: '/api/contract/saveStoreContract',
    sendLinkSms: '/api/contract/sendLinkSms',
    sendOtpSms: '/api/contract/sendOtpSms',
    addCheckboxLog: '/api/contract/addCheckboxLog',
    verifyOtpCode: '/api/contract/verifyOtpCode',
    approveContract: '/api/contract/approveContract',
  },
  pos: {
    folio: '/api/pos/folio/importFolios',
    table: {
      getTables: '/api/pos/table/getTables',
      saveTable: '/api/pos/table/saveTable',
      saveTableGroup: '/api/pos/table/saveTableGroup',
      deleteTableGroup: '/api/pos/table/deleteTableGroup',
      deleteTable: '/api/pos/table/deleteTable',
    },
    payment: {
      getPaymentTypes: '/api/pos/payment/getPaymentTypes',
      savePaymentType: '/api/pos/payment/savePaymentType',
      deletePaymentType: '/api/pos/payment/deletePaymentType',
    },
    printer: {
      getPrinters: '/api/pos/printer/getPrinters',
      savePrinter: '/api/pos/printer/savePrinter',
      deletePrinter: '/api/pos/printer/deletePrinter',
    },
    deleteReason: {
      getdeleteReason: '/api/pos/reason/getReasons',
      savedeleteReason: '/api/pos/reason/saveReason',
      deletedeleteReason: '/api/pos/reason/deleteReason',
    },
    giftReason: {
      getgiftReason: '/api/pos/giftReason/getGiftReasons',
      savegiftReason: '/api/pos/giftReason/savegiftReasons',
      deletegiftReason: '/api/pos/giftReason/deletegiftReasons',
    },
    discountReason: {
      getdiscountReason: '/api/pos/discountReason/getDiscountReasons',
      savediscountReason: '/api/pos/discountReason/saveDiscountReason',
      deletediscountReason: '/api/pos/discountReason/deleteDiscountReason',
    },
    taxRate: {
      gettaxRate: '/api/pos/taxRate/getTaxRates',
      savetaxRate: '/api/pos/taxRate/saveTaxRate',
      deletetaxRate: '/api/pos/taxRate/deleteTaxRate',
    },
    ecrDepartment: {
      getecrDepartment: '/api/pos/ecrDepartments/getEcrDepartments',
      saveecrDepartment: '/api/pos/ecrDepartments/saveEcrDepartment',
      deleteecrDepartment: '/api/pos/ecrDepartments/deleteEcrDepartment',
    },
    'cash-register': '/api/pos/cash-register/deleteCashRegister',
  },
  reservation: {
    saveReservation: '/api/reservation/saveReservation',
    getReservations: '/api/reservation/getReservations',
    getReservationsOfStore: '/api/reservation/getReservationsOfStore',
    saveReservationType: '/api/reservation/saveReservationType',
    getReservationTypes: '/api/reservation/getReservationTypes',
    deleteReservationType: '/api/reservation/deleteReservationType',
    cancelReservation: '/api/reservation/cancelReservation'
  },
  feed: {
    getFeed: '/api/feed/getFeed',
    getNews: '/api/feed/getNews',
    getNewsItem: '/api/feed/getNewsItem',
    saveNewsItem: '/api/feed/saveNewsItem',
    deleteNewsItem: '/api​/feed​/deleteNewsItem',
  },
  category: {
    getCategories: '/api/category/getCategories',
    getAttributes: '/api/category/getAttributes',
  },
  faq: {
    getFAQList: '/api/faq/getFAQList',
    saveFAQItem: '/api/faq/saveFAQItem',
    deleteFAQItem: '/api/faq/deleteFAQItem',
  },
  translate: {
    saveMenuItemTranslate: '/api/translate/saveMenuItemTranslate',
    getMenuItemTranslate: '/api/translate/getMenuItemTranslate',
    saveTranslateItem: '/api/translate/saveTranslateItem',
    getTranslateListById: '/api/translate/getTranslateListById',
    deleteMenuItemTranslate: '/api/translate/deleteMenuItemTranslate',
  },
};
