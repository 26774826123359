import { Component, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Network } from '@capacitor/network';
import { akitaDevtools } from '@datorama/akita';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router) {
    // alert(window.innerHeight)

    // let isUserLogin = localStorage.getItem('userInfoPortal');
    // if(isUserLogin) this.router.navigateByUrl('/main');
     
    if(isDevMode()) akitaDevtools();

    Network.addListener('networkStatusChange', (status) => {
      // alert('Network status changed' +  JSON.stringify(status));
    });
    this.logCurrentNetworkStatus();
  }

  logCurrentNetworkStatus = async () => {
    const status = await Network.getStatus();

    // alert('Network status:' + JSON.stringify(status));
  };
  
}
