import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'maxDate'
})
export class MaxDatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    return moment().add(10, 'year').toISOString();
  }

}
