import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mongo_get_params, mongo_upsert_params } from './dto';
import { Observable } from 'rxjs';
import { IdGeneratorService } from '../helpers/id-generetor.service';
import { UserService } from '../user.service';
import { UserStore } from 'src/app/store/user/user.store';

@Injectable({
  providedIn: 'root'
})
export class PublicApiService {
  apiKey = 'MzR5cjgyMsSxdXJmZmcyamdydWZncmV1amRmbmJodWQ';
  postMongoUrl = 'https://public.kerzz.com:50502/api/database/dataAction';
  constructor(private http: HttpClient,private ig : IdGeneratorService,private us : UserStore) { 

  }


  upsert(prm: mongo_upsert_params): Promise<any> {
    if (!prm.data.id) {
      prm.data.id = this.ig.generate();
    }
    let filter = { id: prm.data.id };
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: 'upsert',
          database: prm._db,
          collection: prm.col,
          filter: filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  upsertGeneric(prm: mongo_upsert_params): Promise<any> {
    if (!prm._filter) {
      prm._filter = { id: prm.data.id };
    }

    return this.http
      .post(
        this.postMongoUrl,
        {
          job: 'upsert',
          database: prm._db,
          collection: prm.col,
          filter: prm._filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  getCollection(prm: mongo_get_params): Observable<any> {
    let filter = prm._filter ? prm._filter : {};
    return this.http.post(
      this.postMongoUrl,
      {
        job: 'get',
        database: prm._db,
        collection: prm.col,
        filter: filter,
        projection: prm._project,
        limit: prm._limit,
        skip: prm._skip,
        sort: prm._sort,
        withTotalCount: prm._withTotalCount,
      },
      this.getHeaders()
    );
  }

  getHeaders() {
    let user = localStorage.getItem('userInfoPortal');
    let token = "";
    if (user) {
      token = JSON.parse(user)['token'];
    }
    let httpOptions = {
      headers: new HttpHeaders({
        "x-user-token": token,
        "x-api-key": this.apiKey,
        'Content-Type': 'application/json', 'Accept': 'application/json'
      })
    }
    return httpOptions
  }
}
