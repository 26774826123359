import { Component, Injector, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { OrwiTableStore } from 'src/app/store/orwi-tables/orwi-table-store';
import { OrwiTableService } from 'src/app/store/orwi-tables/orwitable.service';
import { Location } from '@angular/common';
import { OrwiTables } from 'src/app/services/dto/orwi-tables-model';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { OrwiTableActionChangeComponent } from 'src/app/pages/main/orwi-tables/orwi-table-action-change/orwi-table-action-change.component';
import { OrwiTableQuery } from 'src/app/store/orwi-tables/orwi-table-query';
import { SubscribeManager } from '../../subscribe-manager';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-orwi-tables-main',
  templateUrl: './orwi-tables-main.page.html',
  styleUrls: ['./orwi-tables-main.page.scss'],
})
export class OrwiTablesMainPage extends SubscribeManager implements OnInit {
  dataChange(...data: any[]): void {
    throw new Error(this.transloco.translate('Method not implemented.'));
  }
  myInjector(): Injector {
    throw new Error(this.transloco.translate('Method not implemented.'));
  }
  actions = TABLE_ACTION_TYPES.map(el => ({
    ...el,
    name: this.transloco.translate(el.name)
  }));
  filteredData: OrwiTables[] = [];
  selectedTableIds: string[] = [];
  selectedTables: OrwiTables[] = [];


  calculateActionLabel(e) {
    return this.transloco.translate(
      this.actions.find(action => action.id == e.startAction)?.name
    );
  }

  constructor(
    private orwiTableServise: OrwiTableService,
    private glb: GlobalService,
    public ots: OrwiTableStore,
    public location: Location,
    private helperService: HelperService,
    private orwiTableQuery: OrwiTableQuery,
    private transloco: TranslocoService
  ) {
    super([
      {
        query: orwiTableQuery.tables$,
        service: () => this.getOrwiTables(),
        store: ots,
        storeKey: 'tables',
      },
    ]);
    this.calculateActionLabel = this.calculateActionLabel.bind(this);
  }

  ngOnInit() {
    this.transloco.langChanges$.subscribe(res => {
      this.actions = TABLE_ACTION_TYPES.map(el => ({
        ...el,
        name: this.transloco.translate(el.name)
      }));
    })
  }

  selectionChangedHandler(e) {
    if (e.selectedRowsData.length == 0) return;
    this.selectedTables = e.selectedRowsData;
    console.log(this.selectedTables);
  }

  changeSelectedActions() {
    this.helperService.openModal(
      OrwiTableActionChangeComponent,
      { orwiSelectedTable: this.selectedTables },
      'orwi-table-action-modal',
      true
    );
  }
  updateTable(table) {
    console.log('updateTable', table);
    this.glb.showLoading();
    this.orwiTableServise.saveStoreTable(table.data).then(
      (o: any) => {
        this.glb.toast('Table Updated', '', 'bottom', 'success');
        this.glb.closeLoading();
      },
      (err) => {
        this.glb.toast('There was an error', '', 'bottom', 'danger');
        this.glb.closeLoading();
      }
    );
  }

  ionViewDidEnter() {
    this.getOrwiTables();
  }
  // "in-store" | "take-away" | "loyalty" | "payment" | "self-ordering" | "only-menu" | "delivery" | "coupon" | "pre-paid" | "select-address" | "store-page"

  getOrwiTables() {
    this.glb.showLoading();
    this.orwiTableServise.getOrwiTables().then((response) => {
      this.filteredData = this.ots.getValue().orwiTables;
      console.log('filtered', this.filteredData);
      this.glb.closeLoading();
      console.log(this.actions);
    });
  }

  selectGroup(selecetedGroup) {
    if (selecetedGroup == 'All') {
      this.filteredData = this.ots.getValue().orwiTables;
    } else {
      this.filteredData = this.ots
        .getValue()
        .orwiTables.filter((x) => x.groupName == selecetedGroup);
    }
  }
}

export const TABLE_ACTION_TYPES = [
  {
    id: 'take-away',
    name: 'Take Away',
  },
  {
    id: 'in-store',
    name: 'In Store',
  },
  {
    id: 'delivery',
    name: 'Delivery',
  },
  {
    id: 'only-menu',
    name: 'Only Menu',
  },
]