import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { OrwiStoreQuery } from 'src/app/store/orwi/orwi-store.query';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';

@Component({
  selector: 'app-sync-button',
  templateUrl: './sync-button.component.html',
  styleUrls: ['./sync-button.component.scss'],
})
export class SyncButtonComponent implements OnInit {

  constructor(private glb : GlobalService
    ,private storeService : OrwiStoreService,private sq : OrwiStoreQuery) { }

  ngOnInit() {}

  syncStore()
  {
    this.glb.showLoading()
    this.storeService.syncStore(this.sq.getValue().activeStore.id).then(
      (p: any) => {
        this.glb.closeLoading()
        this.glb.toast('Store has been Synced!', '', 'bottom', 'success');
      },
      (err) => {
        this.glb.closeLoading()
        this.glb.toast('There was an Error!', '', 'bottom', 'danger');
      }
    );
  }

}
