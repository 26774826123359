import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LegalNote } from 'src/app/services/dto/legal-notes-model';

export interface LegalNoteState {
  legalNotes: LegalNote[]
}

export function createInitialState(): LegalNoteState {
  return {
    legalNotes: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'legal-notes',resettable: true })
export class LegalNoteStore extends Store<LegalNoteState> {
  constructor() {
    super(createInitialState());
  }
}
