import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';

@Injectable({
  providedIn: 'root'
})
export class IntrojsService {

  introServe = introJs();

  constructor() { }

  StartTour(){
    this.introServe.start();
  }
  EndTour(){
    this.introServe.exit();
  }
}
