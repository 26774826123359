import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User, UserOwnedStore } from './dto/user-model';
import { UserEventsService } from './events/user-events.service';
import { GlobalService } from './global.service';
import { IdGeneratorService } from './helpers/id-generetor.service';
import { OrwiService } from './orwi.service';
import { requestList } from './request-list';


@Injectable({
  providedIn: 'root',
})
export class UserService { 
  user: User
  stores: UserOwnedStore[]
  token = ""
  userStatus: 'virtual' | 'new' | 'registered'
  constructor(
    private glb :GlobalService,
    private os : OrwiService,
    private plt: Platform,
    private ue :UserEventsService,
    private idGen : IdGeneratorService) {}

  requestOtp(gsm): Promise<'ok' | 'new'> {
    return new Promise((resolve, reject) => {
      
      this.os.serviceRequestPromise(requestList.user.requestOtp, { gsm: gsm, source: this.plt.is("capacitor") ? 'orwi' : 'web' }).then((o: any) => {
        console.log(o.response)
        debugger
        let result = Object.assign(o.response)
        this.glb.consolelog(result)
        this.userStatus = result.message == 'ok' ? "registered" : "new"
        resolve(result.message)
      })
    })

  }

  verifyOtp(_gsm, _otp): Promise<User> {   
    return new Promise((resolve, reject) => {
      this.os.serviceRequestPromise(requestList.user.verifyOtp, { gsm: _gsm, otp: _otp }).then((o: any) => {
        if (o.response) {
          let result = Object.assign(o.response)
          this.user = Object.assign({}, new User(), result.userInfo)
          this.token = result.token
          if (!this.user) {
            this.user = new User()
            this.user.gsm = _gsm
            this.user.id = this.idGen.generate()
          }
          let _userInfo = { gsm: _gsm, token: this.token }
          localStorage.setItem("userInfoPortal", JSON.stringify(_userInfo))
          this.ue.userEvents.next("logged-in")
          resolve(this.user)
        } else if (o.error) {
          reject(o.error.code)
        }

      }, e => {
        reject(e)
      })
    })
  }

  captchaCreate(){
    return new Promise((resolve,reject) => {
      this.os.serviceRequestPromise(requestList.user.createCaptcha, {}).then((o: any) => {
        if(o.response) {
          let result = Object.assign(o.response);
          this.stores = result;
          resolve(this.stores);
          
        } else if(o.error) {
          reject(o.error.code);
        }
      }, e => {
        reject(e);
      })
    })
  }
  captchaVerify(idV , codeV){
    return new Promise((resolve,reject) => {
      this.os.serviceRequestPromise(requestList.user.verifyCaptcha, {id:idV, code: codeV}).then((o: any) => {
        if(o.response) {
          let result = Object.assign(o.response);
          this.stores = result;
          resolve(this.stores);
          
        } else if(o.error) {
          reject(o.error.code);
        }
      }, e => {
        reject(e);
      })
    })
  }

  getOwnedStores(): Promise<UserOwnedStore[]> {
    return new Promise((resolve,reject) => {
      this.os.serviceRequestPromise(requestList.user.getOwnedStores, {}).then((o: any) => {
        if(o.response) {
          let result = Object.assign(o.response);
          debugger
          this.stores = result;
          resolve(this.stores);
          
        } else if(o.error) {
          reject(o.error.code);
        }
      }, e => {
        reject(e);
      })
    })
  }


  

}
