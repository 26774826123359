import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Menu, sidebarMenu } from 'src/app/main-menu';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { OrwiStoreQuery } from 'src/app/store/orwi/orwi-store.query';
import { OrwiStoreStore } from 'src/app/store/orwi/orwi-store.store';
import { StoreListModalComponent } from '../store-list-modal/store-list-modal.component';
import { resetStores } from '@datorama/akita';
import { FormControl } from '@angular/forms';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';
import { UserService } from 'src/app/services/user.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserQuery } from 'src/app/store/user/user.query';
import { IntrojsService } from 'src/app/services/introjs.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private helper: HelperService,
    private router: Router,
    private userQuery: UserQuery,
    private glb : GlobalService,
    private menu: MenuController,
    private orwiStoreQuery: OrwiStoreQuery,
    private orwiStoreService: OrwiStoreService,
    private introService : IntrojsService
  ) {
    this.orwiStoreQuery.activeStore$.subscribe((data) => {
      if (data) {
        this.activeStoreName = data.name;
      }
    });
  }

  showChangeType = false;

  changeType(e: any) {

  }

  appFormControl = new FormControl(OrwiStoreService.appType);

  sideBarMenu: Menu[] = sidebarMenu;
  activeStoreName: string = '';

  openStoreList() {
    this.helper.openModal(StoreListModalComponent, {}, 'magaza-list-modal');
  }

  addNewStore() {
    this.router.navigateByUrl('/main/store-add');
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val['url']) {
        let splitted = String(val['url']).split('/');

        this.sideBarMenu.forEach((elm) => {
          if (elm?.path == val['url']) {
            elm.active = true;
          } else {
            if (elm.children) {
              elm.children.forEach((ch) => {
                if (ch.path == val['url']) {
                  ch.active = true;
                  elm['expanded'] = true;
                } else {
                  ch.active = false;
                }
              });
            } else {
              elm.active = false;
            }
          }
        });
      }
    });
  }

  openMenu(menu: Menu) {
    this.introService.EndTour();  //Her menü geçişinde açık tur varsa sonlandırmaya zorlama.s
    if (menu.tag == 'logout') {
      localStorage.clear();
      resetStores();
      this.router.navigateByUrl('/login', { replaceUrl: true });
    }
    else if (menu.tag == 'orwi-folio-report') {
      // console.log(this.userQuery.getValue().userInfo)
      if (!this.userQuery.getValue().userInfo.isMaster) {
        this.glb.toast('','You do not have access to this module!','bottom','warning')
        return
      }
      else
      {
        this.menu.close();
        this.router.navigateByUrl(menu.path);
      }
    }
     else {
      if (menu.children && menu.children.length > 0) {
        menu['expanded'] = !menu['expanded'];
      } else {
        // debugger
        this.menu.close();
        this.router.navigateByUrl(menu.path);
      }
    }
  }
}
