import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Support } from 'src/app/services/dto/support-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { OrwiStoreQuery } from 'src/app/store/orwi/orwi-store.query';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';
import { UserQuery } from 'src/app/store/user/user.query';
import { UserService } from 'src/app/store/user/user.service';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
})
export class SupportModalComponent implements OnInit {

  constructor(private fb: FormBuilder,public h: HelperService,private glb: GlobalService,public sq : OrwiStoreQuery,private uq : UserQuery,private orwiService :OrwiStoreService) { }

  form = this.fb.group({
    storeId: [this.sq.getValue().activeStore.id, Validators.required],   
    gsm: [this.uq.getValue().userInfo.gsm, Validators.required],
    email: [this.uq.getValue().userInfo.email, Validators.required],
    message: [null, Validators.required],
    subject :[null,Validators.required],
    token :[this.uq.getValue().token,Validators.required],
    name : [this.uq.getValue().userInfo.name,Validators.required]
  });
  
  send() {   
    this.form.markAllAsTouched();
    if(this.form.valid) {
      console.log(this.form.value)
      this.orwiService.sendTicket(this.form.value).then(
        (o: any) => {
          this.h.closeModal();
          this.glb.toast('Your Support Request has been Submitted!', '', 'bottom', 'success');
          this.glb.closeLoading();
        },
        (err) => {
          this.h.closeModal();
          this.glb.toast('There was an Error', '', 'bottom', 'danger');
          this.glb.closeLoading();
        }
      );
    }
  }

  ngOnInit() {}

}
