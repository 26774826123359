import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { OrwiTables } from 'src/app/services/dto/orwi-tables-model';


export interface OrwiTableState {
  orwiTableGroups: [];
  orwiTables: OrwiTables[],
}

export function createInitialState(): OrwiTableState {
  return {
    orwiTableGroups: [],
    orwiTables: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'table',resettable: true })
export class OrwiTableStore extends Store<OrwiTableState> {
  constructor() {
    super(createInitialState());
  }
}
