import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  @Input() rating: number = 0;
  get stars() {
    return Array(Math.floor(this.rating ?? 0)).fill(0);
  }

  get notFilled() {
    return Array(Math.floor((5 - (this.rating ?? 0)) ?? 0)).fill(0);
  }
  constructor() {}

  ngOnInit() {}
}
