import { Injectable } from '@angular/core';
import {
  User,
  UserCountStats,
  UserOwnedStore,
} from 'src/app/services/dto/user-model';
import { OrwiService } from 'src/app/services/orwi.service';
import { requestList } from 'src/app/services/request-list';
import { UserState, UserStore } from './user.store';
import { resetStores } from '@datorama/akita';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private orwiService: OrwiService,
    private userStore: UserStore,
    private router: Router
  ) {}

  setUserInfo(userInfo: User, token: string) {
    //localStorage.setItem('userInfoPortal', JSON.stringify(userInfo));
    this.userStore.update({
      userInfo: userInfo,
      token: token,
    });
  }

  userInfo() {
    let userInfo = localStorage.getItem('userInfoPortal');
    let token = '';
    if (userInfo) token = JSON.parse(userInfo).token;
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.user.autoLogin, { token })
        .then((o: any) => {
          // debugger
          if (o.response) {
            this.userStore.update({
              userInfo: o.response.userInfo,
              token: o.response.token,
            });
            return resolve(o.response);
          }

          if (o.error) {
            if (
              o.error?.name === 'TokenExpiredError' ||
              o.error?.message === 'jwt expired'
            ) {
              localStorage.clear();
              resetStores();
              this.router.navigateByUrl('/login', { replaceUrl: true });
            }
            return reject(o.error.code);
          }
        });
    });
  }

  setOwnedStore(stores: UserOwnedStore[]) {
    this.userStore.update({
      ownedStores: stores,
    });
  }

  _getOwnedStore(): Promise<UserOwnedStore[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.user.getOwnedStores, {})
        .then(
          (o: any) => {
            if (o.response) {
              this.setOwnedStore(Object.assign(o).response);
              return resolve(Object.assign(o).response);
            }

            if (o.error) {
              return reject(o.error.code);
            }
          },
          (e) => {
            return reject(e.error.code);
          }
        );
    });
  }

  saveUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.user.saveUser, user)
        .then((o: any) => {
          if (o.response) {
            let result = Object.assign(o.response);

            resolve(result);
          } else if (o.error) {
            reject(o.error.code);
          }
        });
    });
  }

  saveUserInfo(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.user.saveUser, user)
        .then((o: any) => {
          if (o.response) {
            this.userInfo();
            resolve(o.response);
          } else if (o.error) {
            reject(o.error.code);
          }
        });
    });
  }

  getUserCountStats(): Promise<UserCountStats> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.report.getUserCountStats, {})
        .then((o: any) => {
          if (o.response) {
            resolve(o.response);
          } else if (o.error) {
            reject(o.error.code);
          }
        });
    });
  }
}
