import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySorter',
})
export class ArraySorterPipe implements PipeTransform {
  transform(
    value: any[],
    key: string,
    sortType: 'asc' | 'desc' = 'desc'
  ): any[] {

    if (value && Array.isArray(value)) {
      let formatted = value.sort((a, b) => a[key] - b[key]);
      return sortType == 'asc' ? formatted : formatted.reverse();
    }

    return [];
  }
}
