import { Injectable } from '@angular/core';
import { arrayRemove, arrayUpsert, resetStores } from '@datorama/akita';
import { Product } from 'src/app/services/dto/menu-model';
import {
  MainContract,
  OrwiStore,
  SaveStaff,
  StoreContract,
  StoreStaff,
  StoreTable,
  UploadImage,
} from 'src/app/services/dto/store-model';
import { Support } from 'src/app/services/dto/support-model';
import { GlobalService } from 'src/app/services/global.service';
import { OrwiService } from 'src/app/services/orwi.service';
import { mongo_get_params } from 'src/app/services/public-api-service/dto';
import { PublicApiService } from 'src/app/services/public-api-service/public-api.service';
import { requestList } from 'src/app/services/request-list';
import { UserService } from 'src/app/services/user.service';
import { UserStore } from '../user/user.store';
import { OrwiStoreQuery } from './orwi-store.query';
import { OrwiStoreStore } from './orwi-store.store';
import { Table, TableLog } from 'src/app/services/dto/table-model';
import * as moment from 'moment';
import { SurveyItem } from 'src/app/services/dto/folio-model';

@Injectable({
  providedIn: 'root',
})
export class OrwiStoreService {
  constructor(
    private orwiService: OrwiService,
    private glb: GlobalService,
    private us: UserService,
    private orwiStoreStore: OrwiStoreStore,
    private orwiStoreQuery: OrwiStoreQuery,
    // private menuService: MenuService,
    // private paymentType: PaymentTypeService,
    // private printerService: PrinterService,
    // private foliosService: FoliosService,
    // private faqService: FaqService,
    // private orderService: OrderServiceService,
    // private newsService: AnnouncementService,
    // private tableService: TableService,
    // private definitionsService: DefinitionsService,
    // private couponService: CouponService,
    // private specialAppsService: SpecialAppsService
    private publicApi: PublicApiService,
    private userStore: UserStore
  ) {}

  createQr(): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    return this.orwiService.serviceRequestPromise('/api/helper/createTableQR', {
      width: 1600,
      isBlackWhite: true,
      storeId: storeId,
    });
  }

  assignQr(qrCode): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    return this.orwiService.serviceRequestPromise(
      '/api/helper/updateTableQrStoreId',
      { qrCode: qrCode, storeId: storeId }
    );
  }

  selectStore(storeId: string) {
    localStorage.setItem('activeStore', storeId);
    this._getStoreInfo(storeId).then((x) => {
      this.orwiStoreStore.update({
        activeStore: x,
      });
    });
  }

  changeApiKeyType(type: 'orwi' | 'special') {
    localStorage.setItem('appType', type);
    this.selectStore(localStorage.getItem('activeStore'));
  }

  static get appType() {
    return localStorage.getItem('appType') || 'orwi';
  }
  static get StoreId() {
    return localStorage.getItem('activeStore');
  }

  syncStore(storeId) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.store.syncStoreMenu, {
          id: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(Object.assign(o).response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  _getStoreInfo(storeId: string): Promise<OrwiStore> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/store/storeInfo', { id: storeId })
        .then((o: any) => {
          // this.initalize();
          resetStores({
            exclude: ['owned-stores', 'user'],
          });
          this.orwiStoreStore.update({
            bannerImages: [],
          });
          return resolve(Object.assign(o).response);
        });
    });
  }
  _getStoreMenu(storeId: string): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.store.storeMenu, { id: storeId })
        .then(
          async (o: any) => {
            console.log(o);
            let products: Product[] = Object.assign(o).response.products.filter(
              (x) => x.isShowInStore == true
            );
            let groups = Object.assign(o).response.productGroups;
            // products.filter(x=>x.isShowInStore)

            await products.map((product) => {
              groups.map((group) => {
                if (product.group == group.id) {
                  // console.log("product", product)
                  // console.log("group", group)
                  product.group = group.name;
                }
              });
            });
            return resolve(products);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  getStoreLogo(): Promise<UploadImage> {
    let storeId = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/image/imageStore', { id: storeId })
        .then(
          (o: any) => {
            // this.initalize();
            return resolve(Object.assign(o).response);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }

  getStoreName(storeId: string): string {
    let store = this.orwiStoreQuery
      .getValue()
      .ownedStore.find((store) => store.id == storeId);
    if (store) return store.name;
    return storeId;
  }

  async initalize() {
    await this.glb.showLoading();
    // await this.orderService.getAll();
    // await this.faqService.getAll();
    // await this.menuService.fetchMenu();
    // await this.getStaffList();
    // await this.getCategories();
    // await this.paymentType.getPaymentTypes();
    // await this.printerService.getPrinters();
    // await this.tableService.getTablesAndGroups();
    // await this.couponService.getAll();
    // await this.getBanners();
    // await this.newsService.getAll();
    // await this.foliosService.getFoliosByDate({ today: true });
    // await this.foliosService.getDeliveryFoliosOnOwnedStores({ today: true });
    // await this.definitionsService.initializeDefinitions();
    // await this.specialAppsService.getAllFaqs();
    // await this.specialAppsService.getAllNews();
    await this.glb.closeLoading();
  }

  getStaffList(): Promise<StoreStaff[]> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.store.getStaffList, { id: storeId })
        .then((o: any) => {
          if (o.response) {
            this.orwiStoreStore.update({
              staffs: o.response,
            });
            return resolve(Object.assign(o).response);
          }

          return reject(o.error.code);
        });
    });
  }

  saveStaff(staff: SaveStaff): Promise<StoreStaff> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.user.linkOwnedStore, staff)
        .then((o: any) => {
          if (o.response) {
            this.getStaffList();
            return resolve(Object.assign(o).response);
          }

          return reject(o.error.code);
        });
    });
  }

  removeStaff(userId, storeId): Promise<StoreStaff> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.user.unlinkOwnedStore, {
          userId,
          storeId,
        })
        .then((o: any) => {
          if (o.response) {
            this.getStaffList();
            return resolve(Object.assign(o).response);
          }

          return reject(o.error.code);
        });
    });
  }

  getCategories(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.category.getCategories, {})
        .then((o: any) => {
          if (o.response) {
            // this.orwiStoreStore.update({
            //   categories: o.response,
            // });
            return resolve(o.response);
          }

          return reject(o.error.code);
        });
    });
  }

  updateStore(storeData: OrwiStore): Promise<OrwiStore> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.store.saveStore, {
          ...storeData,
          branchCode: +storeData.branchCode,
        })
        .then((o: any) => {
          console.log(o);
          if (o.response) {
            this.orwiStoreStore.update({
              activeStore: o.response,
            });
            return resolve(o.response);
          }

          return reject(o.error.code);
        });
    });
  }

  getDeliveryQr(id?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.store.getDeliveryQr, {
          id: id,
          width: 1024,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          return reject(o.error.code);
        });
    });
  }

  uploadImage(uploadImage: UploadImage): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    if (uploadImage.itemId == undefined || uploadImage.itemId == null) {
      uploadImage.itemId = storeId;
    }
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.image.saveImage + '/' + storeId, {
          ...uploadImage,
          storeId: storeId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              return resolve(o.response);
            }

            return reject(o.error.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  // deleteImage(itemId): Promise<any> {
  //   let storeId = localStorage.getItem('activeStore');

  //   return new Promise((resolve, reject) => {
  //     this.orwiService
  //       .serviceRequestPromise(requestList.image.deleteImage + '/' + storeId, {
  //         itemId,
  //       })
  //       .then(
  //         (o: any) => {
  //           if (o.response) {
  //             console.log(o)
  //             return resolve(o.response);
  //           }

  //           return reject(o.error.code);
  //         },
  //         (err) => {
  //           return reject(err);
  //         }
  //       );
  //   });
  // }

  saveBanner(uploadImage: UploadImage): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    if (uploadImage.itemId == undefined || uploadImage.itemId == null) {
      uploadImage.itemId = storeId;
    }
    let url = requestList.image.saveImage + '/' + storeId;
    console.log(url);
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(url, {
          ...uploadImage,
          storeId: storeId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              this.orwiStoreStore.update(({ bannerImages, popupImages }) => ({
                bannerImages: arrayUpsert(
                  bannerImages,
                  uploadImage.itemId,
                  uploadImage,
                  'itemId'
                ),
                popupImages: arrayUpsert(
                  popupImages,
                  uploadImage.itemId,
                  uploadImage,
                  'itemId'
                ),
              }));
              return resolve(o.response);
            }

            return reject(o.error.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  deleteBanner(itemId): Promise<any> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.image.deleteImage + '/' + storeId, {
          itemId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              this.orwiStoreStore.update(({ bannerImages, popupImages }) => ({
                bannerImages: arrayRemove(bannerImages, itemId, 'itemId'),
                popupImages: arrayRemove(popupImages, itemId, 'itemId'),
              }));
              return resolve(o.response);
            }

            return reject(o.error.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  getBanners(): Promise<any> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.image.storeBanners, {
          id: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            // console.log('bannerImages',o.response)
            // this.orwiStoreStore.update({
            //   bannerImages: o.response,
            // });
            console.log('getBanners', o.response);
            let sorted = o.response.sort((a, b) => a.sequence - b.sequence);
            return resolve(sorted);
          }

          if (o.error) {
            return reject(o?.error?.code);
          }
          return reject('Bir Sorun Oluştu');
        });
    });
  }

  getPopups(): Promise<any> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.image.popupBanners, {
          id: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            // console.log('bannerImages',o.response)
            // this.orwiStoreStore.update({
            //   bannerImages: o.response,
            // });
            console.log('getBanners', o.response);
            let sorted = o.response.sort((a, b) => a.sequence - b.sequence);
            return resolve(sorted);
          }

          if (o.error) {
            return reject(o?.error?.code);
          }
          return reject('Bir Sorun Oluştu');
        });
    });
  }

  getMenuQr(id?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.menu.menuQR + '/' + id, {
          width: 0,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          if (o.error) {
            return reject(o?.error?.code);
          }
          return reject('Bir Sorun Oluştu');
        });
    });
  }

  getStoreContract(): Promise<StoreContract> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.contract.getStoreContract, {
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          if (o.error) {
            return reject(o?.error?.code);
          }
          return reject('Bir Sorun Oluştu');
        });
    });
  }

  saveStoreContract(contract: StoreContract): Promise<StoreContract> {
    let storeId = localStorage.getItem('activeStore');
    if (contract.adminGsm.startsWith('+') == false) {
      contract.adminGsm = '+' + contract.adminGsm;
    }

    if (contract.phone.startsWith('+') == false) {
      contract.phone = '+' + contract.phone;
    }

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.contract.saveStoreContract, {
          ...contract,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          if (o.error) {
            return reject(o?.error);
          }
          return reject('Bir Sorun Oluştu');
        });
    });
  }

  getMainContract(): Promise<MainContract> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequest('/api/contract/getMainContract', {}, this.us.token)
        .subscribe(
          (o) => {
            if (o != undefined) {
              return resolve(Object.assign(o).response);
            } else return undefined;
          },
          (e) => {
            console.log(Object.assign(e));
            this.glb.toast('', e.error.desc, 'bottom', 'danger', 2000);
            return resolve(Object.assign(e).error);
          }
        );
    });
  }

  saveMainContract(detail): Promise<MainContract> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequest(
          '/api/contract/saveMainContract',
          { detail: detail },
          this.us.token
        )
        .subscribe(
          (o) => {
            if (o != undefined) {
              return resolve(Object.assign(o).response);
            } else return undefined;
          },
          (e) => {
            this.glb.toast('', e.error.desc, 'bottom', 'danger', 2000);
            return resolve(Object.assign(e).error);
          }
        );
    });
  }

  sendStoreContractSms(gsm: string) {
    let storeId = localStorage.getItem('activeStore');
    if (gsm.startsWith('+') == false) {
      gsm = '+' + gsm;
    }
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.contract.sendLinkSms, {
          gsm: gsm,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          if (o.error) {
            return reject(o?.error?.code);
          }
          return reject('Bir Sorun Oluştu');
        });
    });
  }

  sendTicket(ticket: Support) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(requestList.helper.saveFeedback, ticket)
        .then((o: any) => {
          console.log(o);
          if (o.response) {
            return resolve(o.response);
          }

          if (o.error) {
            return reject(o?.error?.code);
          }
          return reject('Bir Sorun Oluştu');
        });
    });
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  getStoreUserPlaces(
    storeIds: string[],
    dates: { startDate; endDate }
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      debugger;
      this.publicApi
        .getCollection({
          _db: this.glb.dbName,
          col: 'user-places',
          _filter: {
            storeId: {
              $in: storeIds,
            },
          },
        })
        .toPromise()
        .then(async (o: any[]) => {
          let district = [
            ...new Map(o.map((item) => [item['userId'], item])).values(),
          ];
          const userIds = o.map((elm) => elm.userId);
          const users = await this.getStoreUsers(userIds);
          const storeTables = await this.getStoreTables(storeIds);
          const tableIds = [...storeTables.map((el) => el.id)];
          const tableLogs = await this.getStoreTablesLog(tableIds, dates);
          moment.locale(localStorage.getItem('lang') ?? 'tr');
          const data = [];
          for (const storeId of storeIds) {
            const tableIdsOfStore = storeTables
              .filter((el) => el.storeId == storeId)
              .map((el) => el.id);
            const logOfTables = tableLogs
              .filter((el) => tableIdsOfStore.includes(el.tableId))
              .map((el) => ({
                ...el,
                createDate: moment(el.createDate).format('MMMM'),
              }));
            const groupByDate = this.groupBy(logOfTables, 'createDate');
            for (const [key, value] of Object.entries(groupByDate)) {
              let unique = [
                ...new Map(
                  (value as any).map((item) => [item['userId'], item])
                ).values(),
              ].length;
              data.push({
                storeId,
                month: key,
                total: (value as any).length,
                unique,
              });
            }
          }

          console.log(data);

          return resolve(data);

          /**
           * Örnek veri
           {
            storeId: 'storeID',
            month: 12,
            tekil: 15,
            toplam: 100
           }
           */

          // şube - ay - tekil - toplam

          const toplam = tableLogs.length;
          const tekil = [
            ...new Map(
              tableLogs.map((item) => [item['userId'], item])
            ).values(),
          ].length;
          console.log(toplam, tekil);
          district = district
            .map((elm) => {
              elm.userName = users.find((u) => u.id === elm.userId)?.name;
              elm.count = tableLogs.filter(
                (oUs) => oUs.userId == elm.userId
              ).length;
              return elm;
            })
            .filter((elm) => elm.userName)
            .filter((elm) => elm.count > 0)
            .sort((a, b) => b.count - a.count);
          console.log(o, district);

          resolve(district);
        });
    });
  }

  getStoresSurveys(storeIds: string[], dates: { startDate; endDate }): Promise<SurveyItem[]> {
    // folio-ratings
    return new Promise((resolve, reject) => {
      this.publicApi
        .getCollection({
          _db: 'orwi-prod',
          col: 'folio-ratings',
          _filter: {
            storeId: {
              $in: storeIds,
            },
            createDate: {
              $gte: new Date(
                moment(dates.startDate).format('YYYY-MM-DD')
              ).toISOString(),
              $lte: new Date(
                moment(dates.endDate).format('YYYY-MM-DD')
              ).toISOString(),
            },
          },
        })
        .toPromise()
        .then(async (o: SurveyItem[]) => {
          let formatted = {};
          o.forEach((item, index, surveys) => {
            let foundedSurveys = {};
            foundedSurveys['ratings'] = {};
            surveys
              .filter(
                (elm) =>
                  elm.folioId == item.folioId && elm.userId == item.userId
              )
              .forEach((el) => {
                Object.entries(el).forEach(
                  ([keyEl, valEl]) => {
                    if(keyEl == 'ratingId') {
                      foundedSurveys['ratings'] = {
                        ...foundedSurveys['ratings'],
                        [valEl] : el.ratingValue
                      }
                    } else {
                      foundedSurveys[keyEl] = valEl
                    }
                  }
                )
              });


            formatted = {
              ...formatted,
              [item.folioId]: {
                ...foundedSurveys,
              },
            };
            
          });
          formatted = Object.values(formatted).map(el => el);
          console.log(formatted);
          
          resolve(formatted as SurveyItem[]);
        });
    });
  }

  getStoreTables(storeIds: string[]): Promise<StoreTable[]> {
    return new Promise((resolve, reject) => {
      debugger;
      this.publicApi
        .getCollection({
          _db: this.glb.dbName,
          col: 'store-tables',
          _filter: {
            storeId: {
              $in: storeIds,
            },
          },
        })
        .toPromise()
        .then(async (o: any[]) => {
          resolve(o);
        });
    });
  }

  getStoreTablesLog(tableIds: string[], dates): Promise<TableLog[]> {
    return new Promise((resolve, reject) => {
      debugger;
      this.publicApi
        .getCollection({
          _db: this.glb.dbName,
          col: 'store-table-logs',
          _filter: {
            action: 'check-in',
            tableId: {
              $in: tableIds,
            },
            createDate: {
              $gte: new Date(
                moment(dates.startDate).format('YYYY-MM-DD')
              ).toISOString(),
              $lte: new Date(
                moment(dates.endDate).format('YYYY-MM-DD')
              ).toISOString(),
            },
          },
        })
        .toPromise()
        .then(async (o: any[]) => {
          resolve(o);
        });
    });
  }

  getStoreUsers(userIds: string[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      debugger;
      this.publicApi
        .getCollection({
          _db: this.glb.dbName,
          col: 'users',
          _filter: {
            id: {
              $in: userIds,
            },
          },
        })
        .toPromise()
        .then((o: any) => {
          resolve(o);
        });
    });
  }

  getStoreCustomers(param: Partial<mongo_get_params>): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.publicApi
        .getCollection({
          col: 'users',
          _db: this.glb.dbName,
          _withTotalCount: true,
          _project: {
            id: true,
            permissions: true,
            name: true,
            gsm: true,
            email: true,
            dateOfBirth: true,
            isBlocked: true,
            gender: true,
            createDate: true,
          },
          ...param,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}

interface TableWithLogs extends StoreTable {
  logs: TableLog[];
}
