import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { UserOwnedStore } from 'src/app/services/dto/user-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { OrwiStoreQuery } from 'src/app/store/orwi/orwi-store.query';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';
import { UserQuery } from 'src/app/store/user/user.query';
import { UserService } from 'src/app/store/user/user.service';
import { UserStore } from 'src/app/store/user/user.store';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.page.html',
  styleUrls: ['./store-list.page.scss'],
})
export class StoreListPage implements OnInit {
  constructor(
    private glb: GlobalService,
    private orwiStoreService: OrwiStoreService,
    private userService: UserService,
    private userQuery: UserQuery,
    private userStore: UserStore,
    private router: Router,
    private helper: HelperService,
    private transcolo: TranslocoService
  ) {
    this.userQuery.ownedStores$.subscribe((data) => {
      data = data.sort((a, b) => {
        return a.storeInfo.name.toLocaleLowerCase() <
          b.storeInfo.name.toLocaleLowerCase()
          ? -1
          : a.storeInfo.name > b.storeInfo.name
          ? 1
          : 0;
      });
      this.stores = data;
      this.filteredStore = data;
    });
  }

  stores: UserOwnedStore[] = [];
  loadingText: string = this.transcolo.translate('Loading stores');

  @Output()
  onSelectStore = new EventEmitter(true);

  @Input() changeActiveStore = true;

  ngOnInit() {
    this.getStores();
  }
  // setFilter(e?: any) {
  //   if (e) {
  //     this.filteredStore = this.filteredStore.filter(
  //       (elm) =>
  //         String(elm.storeInfo.name).indexOf(e) >= 0 ||
  //         String(elm.storeInfo.name)
  //           .toLocaleLowerCase()
  //           .indexOf(String(e).toLocaleLowerCase()) >= 0
  //     ).sort((a, b) => {
  //       return a.storeInfo.name.toLocaleLowerCase() < b.storeInfo.name.toLocaleLowerCase()
  //         ? -1
  //         : a.storeInfo.name > b.storeInfo.name
  //         ? 1
  //         : 0;
  //     });;
  //   } else {
  //     this.filteredStore = this.stores.sort((a, b) => {
  //       return a.storeInfo.name.toLocaleLowerCase() <
  //         b.storeInfo.name.toLocaleLowerCase()
  //         ? -1
  //         : a.storeInfo.name > b.storeInfo.name
  //         ? 1
  //         : 0;
  //     });
  //   }
  // }

  setFilter(e) {
    // debugger
    if (e != '') {
      // let filterData = this.userService.user.store.filter(x=>x.id == e.value)

      this.filteredStore = this.stores.filter((o) =>
        Object.keys(o).some((k) =>
          o.storeInfo.name.toString().toLowerCase().includes(e.toLowerCase())
        )
      );
      if (this.filteredStore.length == 0) {
        this.loadingText = this.transcolo.translate('Store Not Found');
      }
    } else {
      this.filteredStore = this.stores;
    }
  }

  filteredStore = [];
  loaded = false;

  getStores() {
    if (this.userStore.getValue().ownedStores.length == 0) {
      this.glb.showLoading().then();
      this.userService._getOwnedStore().then(
        (stores) => {
          this.glb.closeLoading().then();
          this.loaded = true;
        },
        (e) => {
          this.glb.closeLoading().then();
          this.helper.closeModal();
          if (e == 'user_not_found') {
            localStorage.clear();
            resetStores();
            this.router.navigate(['login']);
          }
          this.loaded = true;
        }
      );
    }
  }

  addNew() {
    this.router.navigateByUrl('/main/store-add');
    this.helper.closeModal();
  }

  selectStore(store: UserOwnedStore) {
    this.onSelectStore.emit(store);
    // console.log(store);
    if(this.changeActiveStore){
      this.orwiStoreService.selectStore(store.storeId);
    }
  }
}
