import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { OrwiStoreService } from 'src/app/store/orwi/orwi-store.service';
import { OrwiStoreStore } from 'src/app/store/orwi/orwi-store.store';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        const error = err.error?.message;
        console.error('error bizde', error);
        if (
          [401].includes(err.status) ||
          error === 'TokenExpiredError: jwt expired' ||
          error?.includes('JsonWebTokenError: invalid signature')
        ) {
          localStorage.clear();
          resetStores();
          this.router.navigateByUrl('/login', { replaceUrl: true });
        }
        return throwError(() => error);
      })
    );
  }
}
