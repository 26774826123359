import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { IntrojsService } from 'src/app/services/introjs.service';

@Component({
  selector: 'app-definitions-table',
  templateUrl: './definitions-table.component.html',
  styleUrls: ['./definitions-table.component.scss'],
})
export class DefinitionsTableComponent implements OnInit {
  filteredDataSource = [];
  @Input()
  set dataSource(e) {
    this.data = e;
    this.filteredDataSource = e;
  }

  data = [];
  @Input()
  orderKey = 'id';

  @Input()
  searchable = true;

  @Output()
  onClickEdit = new EventEmitter();

  @Output()
  onClickDelete = new EventEmitter();

  @Output()
  onClickAdd = new EventEmitter();

  @Input()
  searchKey: string = '';

  @Input()
  columns: DefinitionTableColumn[] = [];

  @Input()
  title = '';

  @Input()
  addTitle = '';

  @Input()
  modalComponent: any;

  constructor(
    private helperService: HelperService,
    private transloco: TranslocoService,
    private introService : IntrojsService
  ) {
    this.customizeText = this.customizeText.bind(this);
    this.calculateSalesAmount = this.calculateSalesAmount.bind(this);
  }

  ngOnInit() {}

  edit(row: any) {
    // this.onClickEdit.emit(row);
    this.modalComponent &&
      this.helperService.openModal(
        this.modalComponent,
        { row: row },
        'kerzz-modal'
      );
  }

  delete(row: any) {
    this.onClickDelete.emit(row);
  }

  add() {
    this.modalComponent &&
      this.helperService.openModal(this.modalComponent, {}, 'kerzz-modal');
    // this.onClickAdd.emit(true);
  }

  filterResult(e: any) {
    if (e) {
      this.filteredDataSource = this.data.filter(
        (elm) =>
          String(elm[this.searchKey]).indexOf(e) >= 0 ||
          String(elm[this.searchKey])
            .toLocaleLowerCase()
            .indexOf(String(e).toLocaleLowerCase()) >= 0
      );
    } else {
      this.filteredDataSource = this.data;
    }
  }

  calculateSalesAmount(clm, cellInfo) {
    if (clm.isRate) {
      return cellInfo[`${clm.dataField}`] / 100;
    }
    return cellInfo[`${clm.dataField}`];
    // if()
  }

  customizeText(column: DefinitionTableColumn, data: any) {
    if (column.customizeText) {
      return column.customizeText(data);
    }
    if (column.translateText) {
      return this.transloco.translate(data.value);
    }
    return String(data.value);
  }

  onCustomizeColumns(columns) {
    for (var i = 0; i < columns.length; i++) columns[i].alignment = 'left';
  }

  openGuide(){
    this.introService.StartTour();
  }
}

export interface DefinitionTableColumn {
  dataField: string;
  caption?: string;
  format?: string;
  hidingPriority?: number;
  precision?: number;
  isRate?: boolean;
  translateText?: boolean;
  customizeText?: (data) => string;
}
