import { OrwiStore } from './store-model';

export class User {
  createDate = new Date();
  updateDate = new Date();
  id = '';
  name = '';
  email = '';
  image = '';
  gsm = '';
  status: 'new' | 'registered' | 'deleted' | 'banned' = 'new';
  dateOfBirth: Date | undefined = undefined;
  gender: 'male' | 'female' | 'undefined' = 'undefined';
  orwiPoints = [];
  coupons = [];
  action: Action = new Action();
  openCheckIn: string = '';
  isMaster: boolean = false;
  isVirtual: boolean = false;
  segment : string = "";
  gsmOTP : string = ""
  geolocation = new GeoLocation();
  source: 'orwi' | 'web' = 'orwi';
  permissions = new UserPermissions();
  isBlocked = false;
}

export class UserPermissions {
  push = true;
  sms = true;
  email = true;
  phone = true;
}

export class GeoLocation {
  latitude = 0;
  longitude = 0;
}

export class Action {
  id: 'standby' | 'action' = 'standby';
  step: 'start' | 'menu' | 'confirm' | 'payment' = 'start';
  storeId = '';
  folioId = '';
  timeStamp = new Date();
}

export class UserQr {
  qrCode: string;
  userId: string;
  createDate: Date;
  qrType: 'user' | 'other';
  code: string;
  appName: string;
  constructor(userId: string, qrCode: string, code: string, appName: string) {
    this.qrCode = qrCode;
    this.userId = userId;
    this.createDate = new Date();
    this.qrType = 'user';
    this.code = code;
    this.appName = appName;
  }
}

export class UserCountStats {
  totalUser: number;
  newUserForMonth: number;
  newUserForDay: number;
}

export class TokenDetail {
  id: string;
  name: string;
  email: string;
  gsm: string;
  constructor(user: User) {
    this.id = user.id;
    this.name = user.name;
    this.email = user.email;
    this.gsm = user.gsm;
  }
}

export class UserOwnedStore {
  userId: string = '';
  storeId: string = '';
  position: 'owner' | 'waiter' | 'manager' = 'owner';
  approved: boolean = true;
  storeInfo: OrwiStore = new OrwiStore();
}
export class UserPermissionLogs {
  userId = '';
  createDate = new Date();
  push = true;
  sms = true;
  email = true;
  phone = true;
  source: 'orwi' | 'web' = 'orwi';
  appName = 'orwi';
}
