import { Pipe, PipeTransform } from '@angular/core';
import { ToolbarService } from 'src/app/services/toolbar-service.service';

@Pipe({
  name: 'arrayFilter',
})
export class ArrayFilterPipe implements PipeTransform {
  constructor(private toolbarService: ToolbarService) {}

  transform(data: any[],term:string,searchKey: string = 'name'): any[] {
    let returned = [];
    returned = data.filter(
      (elm) =>
        String(elm[searchKey]).indexOf(term) >= 0 ||
        String(elm[searchKey])
          .toLocaleLowerCase()
          .indexOf(String(term).toLocaleLowerCase()) >= 0
    );

    console.log(data, searchKey, term);
    return returned;
  }
}
