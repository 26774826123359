import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { resetStores } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class OrwiService {
  private readonly baseUrl = environment.apiUrl;
  private readonly apiKey = environment.apiKey;
  token;
  constructor(
    private http: HttpClient,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private router: Router
  ) {}

  serviceRequest(path, postData, _token = '') {
    if (!this.glb.networkConnected) {
      this.glb.toast('Connection Error', 'No Internet', 'middle', 'danger');
      return undefined;
    }
    this.token = _token;
    let url = `${this.baseUrl}${path}`;
    return this.http.post(url, postData);
  }

  serviceRequestPromise<T = any>(path, postData): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.glb.networkConnected) {
        this.glb.toast('Connection Error', 'No Internet', 'middle', 'danger');
        return undefined;
      }

      if (!navigator.onLine) {
        this.glb.toast('Connection Error', 'No Internet', 'middle', 'danger');
        reject('offline');
        return;
      }

      let url = `${this.baseUrl}${path}`;
      this.http.post<T>(url, postData).subscribe(
        (o) => {
          resolve(o);
        },
        (e) => {
          this.glb.consolelog('error', e);
          if (e.status == 0) {
            setTimeout(() => {
              this.glb.toast(
                'Opss!',
                this.transloco.translate(
                  'There is a problem, try again after a while'
                ),
                'middle',
                'danger'
              );
            }, 500);
          } else {
            setTimeout(() => {
              if (e?.error?.message?.includes('JsonWebTokenError') || e?.error?.message?.includes('jwt expired')) {
                localStorage.clear();
                resetStores();
                this.router.navigate(['login']);
              } else {
                this.glb.toast('Opss!', e.error.message, 'middle', 'danger');
                this.glb.closeLoading();
              }
            }, 500);
          }
          reject(e?.error?.message);
        }
      );
    });
  }

  // getHeaders() {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'content-Type': 'application/json',
  //       'accept': 'application/json',
  //       'apikey': this.apiKey,
  //       'token': this.token,
  //       'region': "tr" //TODO: buraya user class ında region değeri gelicek.
  //     })
  //   }
  //   return httpOptions
  // }

  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  getIP() {
    this.getIPAddress().subscribe((res: any) => {
      this.glb.ipAddress = res.ip;
    });
  }
}
