import { Advices, ModifierCover, Product, ProductGroup } from './menu-model';
import { GeoLocation } from './user-model';

export class OrwiStore {
  id = '';
  name = '';
  serviceTypes = [];
  apiKey: string = undefined;
  properties: Properties = new Properties();
  languages: string[] = [];
  localPayTypes: string[] = [];
  payTypes: string[] = [];
  cloudId: string = '';
  mainAction: MainActions = 'in-store';
  lastMenuUpdate: Date | undefined;
  menuVersion: number = 0;
  orwiPayPoint: number = 0.0;
  payPoint: number = 0.0;
  masterpass = new masterpassConfig();
  kerzzId = '';
  tipEnabled = false;
  workingHours = new Array<WorkingHour>();
  isProduction = true;
  storeCurrency = '₺';
  storeLocale = 'tr';
  parentId = '';
  pointTitle = '';
  maxPoint = 0;
  isVisible = true;
  sharedPoints = false;
  deliveryStoreId = '';
  branchCode: number;
  //*info
  address = '';
  phone = '';
  location: string = '';
  geoLocation = new GeoLocation();
  instagramAddress = '';
  amountPerPerson = 0;
  description = '';
  rate = 0;
  serviceFee: number = 0;
  mainCategoryId = '';
  status: StoreStatuses = 'open';
  subCategories = new Array<string>();
  attributes = new Array<string>();
  benefitType: benefitType;
}

export class Properties {
  callWaiter = false;
  callWallet = false;
  requestCheck = false; //off-line
  reservation = false;
  invoiceActive = false;
  reservationPrice = 0;
  pay = false; //online odeme aktif yerine paytypes
  order = false; //masaya servis
  foodAppAccountId: string = '';
  webMenuActive = false;
  webAppActive = false;
  timedDineIn = false;
  timedTakeAway = false;
  delivery = false;
  selfOrdering = false; //siparis ver ve ode
  selfService = false; //kasa da qr ile ode
  stampActive = false;
  stampConversionRate = 0;
}

export type PayTypes =
  | 'orwi-masterpass'
  | 'local'
  | 'offline-checkout'
  | 'offline-table';
export type MainActions =
  | 'in-store'
  | 'take-away'
  | 'loyalty'
  | 'payment'
  | 'self-ordering'
  | 'only-menu'
  | 'delivery'
  | 'coupon'
  | 'pre-paid'
  | 'select-address'
  | 'store-page';

export type StoreStatuses = 'open' | 'close' | 'rush-hour' | 'out-of-order';
export type benefitType = 'point' | 'discount-basket';

export const StoreStatusTypes = ['out-of-order', 'open', 'close', 'rush-hour'];
export const ActionType = [
  {
    id: 'take-away',
    name: 'Take Away',
  },
  {
    id: 'in-store',
    name: 'Table Service',
  },
  {
    id: 'only-menu',
    name: 'Only Menu',
  },
  {
    id: 'loyalty',
    name: 'Loyalty',
  },
  {
    id: 'payment',
    name: 'Payment',
  },
  {
    id: 'pre-paid',
    name: 'PrePaid',
  },
];

export class StoreMenu {
  id = '';
  name = '';
  products: Product[] = [];
  productGroups: ProductGroup[] = [];
  productModifiers: ModifierCover[] = [];
  productGroupModifiers: ModifierCover[] = [];
  advices: Advices[] = [];
  language = '';
  isDefault = false;
}
export class masterpassConfig {
  serviceUrl = '';
  clientId = '';
}

export class CheckInInfo {
  id = '';
  tableName = '';
  startAction: MainActions = 'take-away';
  store = new OrwiStore();
  coupon = null;
}

export class StoreTable {
  id = '';
  storeId = '';
  groupName = '';
  tableName = '';
  startAction: MainActions = 'take-away';
  /**
   * @deprecated use tableQrs shortCode
   */
  code = '';
  /**
   * @deprecated use tableQrs code
   */
  qrId = '';
  active = true;
  tableQrs = new Array<StoreTableQr>();
}

export class StoreTableQr {
  shortCode: string = '';
  code: string = '';
}

export class SaveStaff {
  userQr = '';
  storeId = '';
  position: 'owner' | 'waiter' | 'manager' = 'waiter';
}

export class StoreStaff {
  userId = '';
  userName = '';
  position: 'owner' | 'waiter' | 'manager' = 'waiter';
}

export class WorkingHour {
  day = 0;
  open = '';
  close = '';
  closed = false;
}
export class TableSection {
  //posta
  id = '';
  storeId = '';
  name = '';
  staffs = new Array<string>(); // userID'ler
  tables = new Array<string>();
}

export class UploadImage {
  itemType: ImageTypes;
  itemId?: string;
  imageSize?: string;
  base64: string;
  storeId?: string;
  sequence?: number;
  rootLink?: string;
}

export type ImageTypes =
  | 'product'
  | 'productGroup'
  | 'news'
  | 'store-banner'
  | 'menu-banner'
  | 'store'
  | 'address-banner'
  | 'popup';

export enum imageItemTypes {
  product = 'product',
  productGroup = 'productGroup',
  storeBanner = 'store-banner',
  menuBanner = 'menu-banner',
  store = 'store', // Logo
  addressBanner = 'address-banner',
}

export interface StoreContract {
  id: string;
  createDate: string;
  mainContractId?: string;
  storeId: string;
  commercialName: string;
  taxOffice: string;
  taxNo: string;
  city: string;
  town: string;
  address: string;
  phone: string;
  adminName: string;
  adminGsm: string;
  adminMail: string;
  iban: string;
  detail: string;
  status: contractStatus;
}

export class MainContract {
  id: string = '';
  createDate: Date = new Date();
  detail: string = '';
  version: number = 0;
  default: boolean = true;
}

export type contractStatus =
  | 'none'
  | 'link-sms-sended'
  | 'otp-sms-sended'
  | 'otp-verified'
  | 'approved'
  | 'not-approved';
