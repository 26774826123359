import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-profile-menu-popover',
  templateUrl: './profile-menu-popover.component.html',
  styleUrls: ['./profile-menu-popover.component.scss'],
})
export class ProfileMenuPopoverComponent implements OnInit {
  constructor(
    private router: Router,
    private popOverController: PopoverController
  ) { }

  ngOnInit() { }

  handle(type) {
    switch (type) {
      case 'profile':
        this.router.navigate(['main/profile']);
        break;

      case 'account-transactions':
        this.router.navigate(['main/account-transactions']);
        break;
      case 'contract-text':
        this.router.navigate(['main/contract-text']);
        break;
      case 'logout':
        localStorage.clear();
        resetStores();
        this.router.navigate(['login']);
        break;

      default:
        break;
    }
    this.popOverController.dismiss();
  }
}
